import { RootState } from '../../app/store';

/**
 * Селектор получения списка групп промокодов
 */
export const selectPromoCodeGroupList = () => (state: RootState) => state.promoCode.groupList;

/**
 * Селектор получения списка промокодов
 */
export const selectPromoCodeList = () => (state: RootState) => state.promoCode.promoCodeList;

/**
 * Селектор получения информации о промокоде
 */
export const selectPromoCodeInfo = () => (state: RootState) => state.promoCode.promoCodeInfo;

/**
 * Селектор получения фильтра промокода
 */
export const selectPromoCodeFilters = () => (state: RootState) => state.promoCode.filterPromoCode;

/**
 * Селектор получения флага загрузки списка промокодов и фильтров
 */
export const selectIsLoadingPromoCodeList = () => (state: RootState) => {
  const promoCodeState = state.promoCode;

  return promoCodeState.filterPromoCode.isLoading || promoCodeState.promoCodeList.isLoading;
};
