import { FC, useEffect, useState } from 'react';
import styles from './PromoCodeForm.module.scss';
import VerticalContainer from '../../../components/VerticalContainer';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Combobox } from '@consta/uikit/Combobox';
import {
  BrandId,
  CreatePromoCodeDTO,
  Discount,
  GenerationSetting,
  ScheduleByForm,
  TypeDiscountEnum,
  WeekdayEnum,
} from '../../../types/serverInterface/promoCodeDTO';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { getInputNumberValue } from '../../../helpers/ inputHelpers';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectPromoCodeGroupList } from '../../../state/promoCode/selectors';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import {
  createPromoCodeAction,
  createPromoCodeGroupAction,
  generatePromoCodeAction,
  getPromoCodeGroupListAction,
} from '../../../state/promoCode/actions';
import { enumToArray } from '../../../types/enums';
import { useNavigate } from 'react-router-dom';
import PromoCodeSchedulesForm from '../PromoCodeSchedulesForm';
import { transformDate } from '../../../helpers/transformDate';
import { getMachineListByOrganizationIdAction } from '../../../state/machineControl/actions';
import { selectSmallMachineList } from '../../../state/machineControl/selectors';
import { IconRevert } from '@consta/uikit/IconRevert';
import { MachineSmallListItemDTO } from '../../../types/serverInterface/machineDTO';
import {
  createPromoCodeInitialErrors,
  createPromoCodeInitialValue,
  getCreatePromoCodeFieldError,
} from './helpers';
import TextFieldWithTooltip from '../../../components/withTooltip/TextField';
import { useTranslation } from 'react-i18next';
import {
  initialFieldError,
  minMaxValidate,
  onlyEnglishLettersValidate,
  requiredValidate,
} from '../../../helpers/validateHelpers';
import ComboboxWithTooltip from '../../../components/withTooltip/Combobox/ComboboxWithTooltip';
import DatePickerWithTooltip from '../../../components/withTooltip/DatePicker';
import PromoCodeProductForm from '../PromoCodeProductForm';

/**
 * Фнукция форматирования даты
 *
 * @param date дата
 */
export function formatDate(date: Date | null): string | null {
  if (!date) return null;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

/**
 * Список дней недели
 */
const weekDayList = enumToArray(WeekdayEnum);

const initialBrandsValue: BrandId[] = [
  {
    id: null,
    ingredientLines: null,
  },
];

/**
 * Форма созданимя промокода
 */
const CreatePromoCode: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const organizationId = getOrganizationId();

  const { state: groupList } = useAppSelector(selectPromoCodeGroupList());
  const { state: machineList } = useAppSelector(selectSmallMachineList());

  const [formState, setFormState] = useState(createPromoCodeInitialValue);
  const [formErrors, setFormErrors] = useState(createPromoCodeInitialErrors);

  useEffect(() => {
    organizationId && dispatch(getPromoCodeGroupListAction(organizationId));
    organizationId && dispatch(getMachineListByOrganizationIdAction(organizationId));
  }, [dispatch, organizationId]);

  const getMachinesValue = (arr: number[] | null): MachineSmallListItemDTO[] => {
    const array: MachineSmallListItemDTO[] = [];

    (arr || []).forEach((item) => {
      const machine = machineList?.find(({ id }) => id === item);

      machine && array.push(machine);
    });

    return array;
  };

  const validateForm = () => {
    let isValid = true;

    const setIsValid = (value: boolean) => {
      isValid = isValid ? value : isValid;
    };

    const {
      code,
      generationSetting,
      isGenerateSeveral,
      isFullMachine,
      machineIds,
      discount,
      isUnlimitedTimeUse,
      periodUse,
      qty,
      isUnlimitedCountUse,
    } = formState;
    const { amount, type } = discount;
    const { qty: qtyGeneral, prefix, qtyLetter } = generationSetting;
    const fullMaxCodeLength = 12;
    const minQtyLetter = 3;
    const maxPrefixLength = 6;
    const minPrefixLength = 0;
    const minQty = 1;
    const maxQty = 100;
    const minQtyGeneral = 1;
    const maxQtyGeneral = 100;

    setFormErrors({
      ...createPromoCodeInitialErrors,
      code: isGenerateSeveral
        ? initialFieldError
        : requiredValidate(
            code,
            setIsValid,
            minMaxValidate(
              fullMaxCodeLength,
              minQtyLetter,
              code?.length || 0,
              setIsValid,
              onlyEnglishLettersValidate(code, setIsValid),
            ),
          ),
      // это для главные настроек
      qtyGeneral: isGenerateSeveral
        ? requiredValidate(
            qtyGeneral,
            setIsValid,
            minMaxValidate(maxQtyGeneral, minQtyGeneral, qtyGeneral || 0, setIsValid),
          )
        : initialFieldError,
      prefix: isGenerateSeveral
        ? requiredValidate(
            prefix?.length,
            setIsValid,
            minMaxValidate(
              maxPrefixLength,
              minPrefixLength,
              prefix?.length || 0,
              setIsValid,
              onlyEnglishLettersValidate(prefix, setIsValid),
            ),
          )
        : initialFieldError,
      qtyLetter: isGenerateSeveral
        ? requiredValidate(
            qtyLetter,
            setIsValid,
            minMaxValidate(
              fullMaxCodeLength - (prefix?.length || 0),
              minQtyLetter,
              qtyLetter || 0,
              setIsValid,
            ),
          )
        : initialFieldError,
      machineIds: isFullMachine
        ? initialFieldError
        : requiredValidate((machineIds && machineIds.length) || null, setIsValid),
      type: requiredValidate(type, setIsValid),
      amount:
        type === TypeDiscountEnum.FREE
          ? initialFieldError
          : requiredValidate(
              amount,
              setIsValid,
              type === TypeDiscountEnum.PERCENT
                ? minMaxValidate(100, 0, amount || -1, setIsValid)
                : minMaxValidate(1000, 0, amount || -1, setIsValid),
            ),
      periodUse: isUnlimitedTimeUse
        ? initialFieldError
        : requiredValidate(periodUse.to, setIsValid),
      qty: isUnlimitedCountUse
        ? initialFieldError
        : requiredValidate(qty, setIsValid, minMaxValidate(maxQty, minQty, qty || 0, setIsValid)),

      // Служебные поля
      isUnlimitedTimeUse: initialFieldError,
      isUnlimitedCountUse: initialFieldError,
      isFullMachine: initialFieldError,
      // Не требуется проверка
      schedules: initialFieldError,
      description: initialFieldError,
      groupId: initialFieldError,
      organizationId: initialFieldError,
      generationSetting: initialFieldError,
      product: initialFieldError,
    });

    return { isValid };
  };

  // Обработчики
  const handleChange =
    (key: keyof CreatePromoCodeDTO) =>
    ({ value }: { value: string | number | null }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleBooleanChange =
    (key: keyof CreatePromoCodeDTO) =>
    ({ checked }: { checked: boolean }) => {
      setFormState((prevState) => ({ ...prevState, [key]: checked }));
    };

  const handleGenerationSettingChange =
    (key: keyof GenerationSetting) =>
    ({ value }: { value: string | null }) => {
      setFormState((prevState) => ({
        ...prevState,
        generationSetting: { ...prevState.generationSetting, [key]: value },
      }));
    };

  const handleDiscountChange =
    (key: keyof Discount) =>
    ({ value }: { value: string | null }) => {
      setFormState((prevState) => {
        const type = (key === 'type' ? value : prevState.discount.type) as TypeDiscountEnum;
        const amount =
          key === 'amount' ? (value ? Number(value) : null) : prevState.discount.amount;

        const getNewAmount = () => {
          switch (type) {
            case TypeDiscountEnum.FREE:
              return 0;
            case TypeDiscountEnum.FIXED:
              return amount && (amount > 500 ? 500 : amount < 0 ? 0 : amount);
            case TypeDiscountEnum.PERCENT:
              return amount && (amount > 100 ? 100 : amount < 0 ? 0 : amount);
          }
        };

        return {
          ...prevState,
          discount: {
            ...prevState.discount,
            amount: getNewAmount(),
            type,
          },
        };
      });
    };

  const handlePeriodUseChange = ({ value }: { value: (Date | undefined)[] | null }) => {
    value &&
      setFormState((prevState) => ({
        ...prevState,
        periodUse: {
          to: value[1] || null,
          from: value[0] || null,
        },
      }));
  };

  const handleSubmit = () => {
    validateForm().isValid
      ? dispatch(
          createPromoCodeAction({
            ...formState,
            generationSetting: {
              qty: formState.generationSetting.qty && Number(formState.generationSetting.qty),
              prefix: formState.generationSetting.prefix,
              qtyLetter:
                formState.generationSetting.qtyLetter &&
                Number(formState.generationSetting.qtyLetter),
            },
            code: formState.code?.toUpperCase() || '',
            periodUse: {
              ...formState.periodUse,
              to: formatDate(formState.periodUse.to),
              from: formatDate(formState.periodUse.from),
            },
            schedules: formState.schedules?.map((item) => ({
              ...item,
              to: transformDate(item.to),
              from: transformDate(item.from),
              weekday: weekDayList.filter((weekDay) => item.weekday[weekDay]),
            })),
            qty: formState.qty ? Number(formState.qty) : null,
            organizationId,
            machineIds: formState.isFullMachine ? null : formState.machineIds,
            discount: {
              ...formState.discount,
              amount: formState.discount.amount ? Number(formState.discount.amount) : null,
            },
            product: {
              ...formState.product,
              brands: formState.product.brands.filter(({ id }) => id),
            },
          }),
        ).then(() => navigate('/promoCode/list'))
      : document.getElementById('appContent')?.scrollTo(0, 0);
  };

  const handleSchedulesChange = (list: ScheduleByForm[]) => {
    setFormState((prevState) => ({ ...prevState, schedules: list }));
  };

  const handlePromoSingleGenerateClick = () => {
    organizationId &&
      dispatch(generatePromoCodeAction(organizationId)).then((res) => {
        setFormState((prevState) => ({ ...prevState, code: res.code }));
      });
  };

  const handleCancelClick = () => navigate('/promoCode/list');

  // render методы
  const renderGenerateSettings = () => {
    const qtyLetterError = getCreatePromoCodeFieldError(formErrors)('qtyLetter');
    const prefixError = getCreatePromoCodeFieldError(formErrors)('prefix');
    const qtyGeneralError = getCreatePromoCodeFieldError(formErrors)('qtyGeneral');

    return (
      formState.isGenerateSeveral && (
        <>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.promoCodeQty')}</Text>
            <HorizontalContainer className={styles.fieldWrapper}>
              <TextFieldWithTooltip
                tooltipProps={{
                  content: qtyGeneralError.label
                    ? t(qtyGeneralError.label, qtyGeneralError.optionsObj)
                    : t('promoCode.create.qtyGeneral.tooltip.text'),
                }}
                status={qtyGeneralError.status}
                placeholder={t('promoCode.create.qtyGeneral.textField.placeholder')}
                type="number"
                incrementButtons={false}
                value={getInputNumberValue(formState.generationSetting.qty)}
                onChange={handleGenerationSettingChange('qty')}
              />
            </HorizontalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.generatePromoCode')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <FieldGroup>
                <TextFieldWithTooltip
                  tooltipProps={{
                    content: prefixError.label
                      ? t(prefixError.label, prefixError.optionsObj)
                      : t('promoCode.create.prefix.tooltip.text'),
                  }}
                  status={prefixError.status}
                  placeholder={t('promoCode.create.prefix.textField.placeholder')}
                  value={formState.generationSetting.prefix}
                  onChange={({ value }) =>
                    handleGenerationSettingChange('prefix')({ value: value && value.toUpperCase() })
                  }
                />
                <TextFieldWithTooltip
                  tooltipProps={{
                    content: qtyLetterError.label
                      ? t(qtyLetterError.label, qtyLetterError.optionsObj)
                      : t('promoCode.create.qtyLetter.tooltip.text'),
                  }}
                  status={qtyLetterError.status}
                  placeholder={t('promoCode.create.qtyLetter.textField.placeholder')}
                  type="number"
                  incrementButtons={false}
                  value={getInputNumberValue(formState.generationSetting.qtyLetter)}
                  onChange={handleGenerationSettingChange('qtyLetter')}
                />
              </FieldGroup>
            </VerticalContainer>
          </HorizontalContainer>
        </>
      )
    );
  };

  const renderMachine = () => {
    const machineListError = formErrors.machineIds;

    return (
      <VerticalContainer className={styles.card} space="2xl">
        <Text size="2xl">{t('promoCode.create.titles.machinesSetting')}</Text>
        <Checkbox
          checked={formState.isFullMachine}
          label={t('promoCode.create.isFullMachine.checkbox.label')}
          onChange={handleBooleanChange('isFullMachine')}
        />
        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.machines')}</Text>
            <HorizontalContainer className={styles.fieldWrapper} space="m">
              <ComboboxWithTooltip
                tooltipProps={{
                  content: machineListError.label
                    ? t(machineListError.label, machineListError.optionsObj)
                    : t('promoCode.create.machines.tooltip.text'),
                }}
                status={machineListError.status}
                placeholder={t('promoCode.create.machines.combobox.placeholder')}
                disabled={formState.isFullMachine}
                value={getMachinesValue(formState.machineIds)}
                items={machineList || []}
                getItemLabel={({ name }) => name}
                getItemKey={({ id }) => id}
                multiple
                onChange={({ value }) => {
                  const arr = (value || []).map((item) => item.id);

                  setFormState((prevState) => ({ ...prevState, machineIds: arr }));
                }}
              />
            </HorizontalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </VerticalContainer>
    );
  };

  const renderBaseInfo = () => {
    const typeError = getCreatePromoCodeFieldError(formErrors)('type');
    const amountError = getCreatePromoCodeFieldError(formErrors)('amount');
    const codeError = getCreatePromoCodeFieldError(formErrors)('code');
    const periodUseError = getCreatePromoCodeFieldError(formErrors)('periodUse');
    const qtyError = getCreatePromoCodeFieldError(formErrors)('qty');

    return (
      <VerticalContainer className={styles.card} space="3xl">
        <Text size="2xl">{t('promoCode.create.titles.base')}</Text>
        {/* Промокод */}
        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.promoCode')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <HorizontalContainer space="s">
                <TextFieldWithTooltip
                  tooltipProps={{
                    content: t(codeError.label || t('promoCode.create.code.tooltip.text')),
                  }}
                  status={codeError.status}
                  placeholder={t('promoCode.create.code.textField.placeholder')}
                  disabled={formState.isGenerateSeveral}
                  value={formState.code?.toUpperCase()}
                  width="full"
                  onChange={handleChange('code')}
                />
                <Button
                  onlyIcon
                  disabled={formState.isGenerateSeveral}
                  iconLeft={IconRevert as any}
                  view="ghost"
                  onClick={handlePromoSingleGenerateClick}
                />
              </HorizontalContainer>
              <Checkbox
                checked={formState.isGenerateSeveral}
                label={t('promoCode.create.isGenerateSeveral.checkbox.label')}
                onChange={handleBooleanChange('isGenerateSeveral')}
              />
            </VerticalContainer>
          </HorizontalContainer>
          {renderGenerateSettings()}
        </VerticalContainer>

        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.discount')}</Text>
            <HorizontalContainer className={styles.fieldWrapper} space="m">
              <ComboboxWithTooltip
                tooltipProps={{
                  content: typeError.label
                    ? t(typeError.label, typeError.optionsObj)
                    : t('promoCode.create.discountType.tooltip.text'),
                }}
                status={typeError.status}
                placeholder={t('promoCode.create.discountType.combobox.placeholder')}
                items={enumToArray(TypeDiscountEnum) as string[]}
                value={formState.discount.type}
                getItemLabel={(item) => t(`promoCode.amountType.${item}`)}
                getItemKey={(item) => item}
                onChange={handleDiscountChange('type')}
              />
              <TextFieldWithTooltip
                tooltipProps={{
                  content: amountError.label
                    ? t(amountError.label, amountError.optionsObj)
                    : t('promoCode.create.discountAmount.tooltip.text'),
                }}
                status={amountError.status}
                placeholder={t('promoCode.create.discountAmount.textField.placeholder')}
                disabled={
                  formState.discount.type === TypeDiscountEnum.FREE || !formState.discount.type
                }
                rightSide={
                  formState.discount.type
                    ? t(
                        `promoCode.create.discountAmount.textField.rightSide.${formState.discount.type}`,
                      )
                    : ''
                }
                type="number"
                incrementButtons={false}
                value={
                  formState.discount.type === TypeDiscountEnum.FREE || !formState.discount.type
                    ? null
                    : getInputNumberValue(formState.discount.amount)
                }
                onChange={handleDiscountChange('amount')}
              />
            </HorizontalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.periodUse')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <DatePickerWithTooltip
                tooltipProps={{
                  content: periodUseError.label
                    ? t(periodUseError.label, periodUseError.optionsObj)
                    : t('promoCode.create.periodUse.tooltip.text'),
                }}
                status={periodUseError.status}
                // className={styles.time}
                type="date-range"
                // format="HH:mm"
                // placeholder="ЧЧ:ММ"
                disabled={formState.isUnlimitedTimeUse}
                multiplicitySeconds={0}
                multiplicityMinutes={5}
                value={[formState.periodUse.from || undefined, formState.periodUse.to || undefined]}
                onChange={handlePeriodUseChange}
                // onChange={handleChange(index)}
              />
              <Checkbox
                checked={formState.isUnlimitedTimeUse}
                label={t('promoCode.create.isUnlimitedTimeUse.checkbox.label')}
                onChange={handleBooleanChange('isUnlimitedTimeUse')}
              />
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.qty')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <TextFieldWithTooltip
                tooltipProps={{
                  content: qtyError.label
                    ? t(qtyError.label, qtyError.optionsObj)
                    : t('promoCode.create.qty.tooltip.text'),
                }}
                status={qtyError.status}
                placeholder={t('promoCode.create.qty.textField.placeholder')}
                value={getInputNumberValue(formState.qty)}
                disabled={formState.isUnlimitedCountUse}
                type="number"
                incrementButtons={false}
                onChange={handleChange('qty')}
              />
              <Checkbox
                checked={formState.isUnlimitedCountUse}
                label={t('promoCode.create.isUnlimitedCountUse.checkbox.label')}
                onChange={handleBooleanChange('isUnlimitedCountUse')}
              />
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.group')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <Combobox
                items={groupList || []}
                value={groupList?.find(({ id }) => id === formState.groupId)}
                placeholder={t('promoCode.create.group.combobox.placeholder')}
                getItemLabel={({ name }) => name}
                getItemKey={({ id }) => id}
                onChange={({ value }) =>
                  handleChange('groupId')({ value: value ? value.id || null : null })
                }
                onCreate={({ label }) => {
                  organizationId &&
                    dispatch(createPromoCodeGroupAction({ name: label, organizationId })).then(
                      (res) => {
                        dispatch(getPromoCodeGroupListAction(organizationId)).then(() => {
                          setFormState((prevState) => ({ ...prevState, groupId: res.id }));
                        });
                      },
                    );
                }}
              />
            </VerticalContainer>
          </HorizontalContainer>
        </VerticalContainer>

        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.create.titles.description')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <TextField
                placeholder={t('promoCode.create.description.textField.placeholder')}
                value={formState.description}
                type="textarea"
                minRows={5}
                onChange={handleChange('description')}
              />
            </VerticalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </VerticalContainer>
    );
  };

  const renderActions = () => (
    <HorizontalContainer justify="end">
      <Button label={t('promoCode.create.actions.back')} view="clear" onClick={handleCancelClick} />
      <Button label={t('promoCode.create.actions.save')} onClick={handleSubmit} />
    </HorizontalContainer>
  );

  return (
    <VerticalContainer className={styles.PromoCodeForm} space="5xl">
      {renderBaseInfo()}
      <PromoCodeSchedulesForm
        list={formState.schedules}
        onSchedulesChange={handleSchedulesChange}
      />
      {renderMachine()}
      <PromoCodeProductForm
        product={formState.product}
        onCellCategoryChange={(ids) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              categoryIds: ids,
              brands: initialBrandsValue,
            },
          }));
        }}
        onCellViewChange={(ids) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              viewIds: ids,
              brands: initialBrandsValue,
            },
          }));
        }}
        onBrandChange={(index) => (brandId) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: [
                ...prevState.product.brands.slice(0, index),
                {
                  id: brandId,
                  ingredientLines: null,
                },
                ...prevState.product.brands.slice(index + 1),
              ],
            },
          }));
        }}
        onIngredientLineChange={(index) => (lineIds) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: prevState.product.brands.map((brand, idx) => {
                if (idx === index) {
                  return {
                    ...brand,
                    ingredientLines: lineIds
                      ? lineIds.map((id) => ({ id, ingredients: null }))
                      : null,
                  };
                }
                return brand;
              }),
            },
          }));
        }}
        onIngredientChange={(index) => (ingredients) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: prevState.product.brands.map((brand, idx) => {
                if (idx === index) {
                  return {
                    ...brand,
                    ingredientLines: ingredients,
                  };
                }
                return brand;
              }),
            },
          }));
        }}
        onDeleteClick={(index) => () => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: prevState.product.brands.filter((_, idx) => idx !== index),
            },
          }));
        }}
        onAddClick={() => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: [
                ...prevState.product.brands,
                {
                  id: null,
                  ingredientLines: null,
                },
              ],
            },
          }));
        }}
      />
      {renderActions()}
    </VerticalContainer>
  );
};

export default CreatePromoCode;
