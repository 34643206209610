import { FC, useState } from 'react';
import { Combobox } from '@consta/uikit/Combobox';
import { Checkbox } from '@consta/uikit/Checkbox';
import DefaultModal from '../../../../../../components/DefaultModal';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks/store';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import styles from './DisconnectModal.module.scss';
import AlertButton from '../../../../../../components/AlertButton';
import { disconnectMachineAction } from '../../../../../../state/machineControl/actions';
import { enumToArray } from '../../../../../../types/enums';
import { ConnectionStatus } from '../../../../../../types/serverInterface/machineDTO';
import { selectMachineBaseInfo } from '../../../../../../state/machineControl/selectors';
import MachineOfflineBlockModal from '../../../MachineOfflineBlockModal';
import { ShutdownReasonFilters } from '../../../../../../types/serverInterface/machineListDTO';

/**
 * Свойства компонента DisconnectModal
 */
type DisconnectModalProps = {
  /**
   * id автомата
   */
  machineId: number;
  /**
   * Флаг открытия модального окна отключения автомата от телеметрии
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия модального окна отключения автомата от телеметрии
   */
  onDisconnectModalClose: () => void;
};

/**
 * Форма отключения автомата от телеметрии
 */
type DisconnectModalForm = {
  /**
   * Причина отключения
   */
  shutdownReason: ShutdownReasonFilters | null;
  /**
   * Флаг сброса остатков
   */
  isDropStock: boolean;
};

/**
 * Дефолтное значения формы отключения автомата от телеметрии
 */
const formInitialValue: DisconnectModalForm = {
  isDropStock: false,
  shutdownReason: ShutdownReasonFilters.IN_STOCK,
};

/**
 * Список причин отключения автомата от телеметрии
 */
const disconnectTypeList = enumToArray(ShutdownReasonFilters);

const getItemLabel = (item: ShutdownReasonFilters) => {
  switch (item) {
    case ShutdownReasonFilters.IN_STOCK:
      return 'На склад';
    case ShutdownReasonFilters.ON_REPAIR:
      return 'На ремонт';
  }
};

/**
 * Модальное окно отключения автомата от телеметрии
 */
const DisconnectModal: FC<DisconnectModalProps> = ({
  machineId,
  isOpen,
  onDisconnectModalClose,
}) => {
  const dispatch = useAppDispatch();

  const [form, setForm] = useState(formInitialValue);
  const [isOpenEditOfflineBlockModal, setIsOpenEditOfflineBlockModal] = useState(false);

  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  // Обработчики
  const handleShutdownReasonChange = ({ value }: { value: string | null }) => {
    setForm((prevState) => ({
      ...prevState,
      shutdownReason: value ? (value as ShutdownReasonFilters) : null,
    }));
  };

  const handleIsDropStockChange = ({ checked }: { checked: boolean }) =>
    setForm((prevState) => ({ ...prevState, isDropStock: checked }));

  const handleSubmit = () => {
    if (machineBaseInfo && machineBaseInfo.connectionStatus === ConnectionStatus.OFFLINE) {
      setIsOpenEditOfflineBlockModal(true);
      return null;
    }

    form.shutdownReason &&
      dispatch(disconnectMachineAction(machineId, form.shutdownReason, form.isDropStock)).then(
        () => {
          onDisconnectModalClose();
        },
      );
  };

  const handleEditOfflineBlockModalClose = () => {
    setIsOpenEditOfflineBlockModal(false);
  };

  const handleEditOfflineBlockModalRepeatClick = () => {
    handleSubmit();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button label="Отмена" view="clear" onClick={onDisconnectModalClose} />
      <AlertButton label="Отключить" onClick={handleSubmit} />
    </>
  );

  return (
    <DefaultModal
      className={styles.DisconnectModal}
      isOpen={isOpen}
      modalTitle="Отключить автомат"
      onClose={onDisconnectModalClose}
      renderActions={renderActions}
    >
      <MachineOfflineBlockModal
        isOpen={isOpenEditOfflineBlockModal}
        onCancel={handleEditOfflineBlockModalClose}
        onRepeatClick={handleEditOfflineBlockModalRepeatClick}
      />
      <Combobox
        className={styles.field}
        label="Причина отключения"
        items={disconnectTypeList}
        value={form.shutdownReason}
        getItemLabel={getItemLabel}
        getItemKey={(item: ShutdownReasonFilters) => item}
        onChange={handleShutdownReasonChange}
      />
      <Checkbox
        checked={form.isDropStock}
        label="Сбросить остатки автомата"
        onChange={handleIsDropStockChange}
      />
    </DefaultModal>
  );
};

export default DisconnectModal;
