import { RootState } from '../../app/store';
import { LocaleDTO } from '../../types/serverInterface/localeDTO';

/**
 * Селектор получения списка брендов
 */
export const selectBrandList = () => (state: RootState) => state.productBase.brandList || [];

/**
 * Селектор получения бренда по id
 *
 * @param brandId id бренда
 */
export const selectBrandById = (brandId: number | undefined) => (state: RootState) => {
  const brandList = state.productBase.brandList;
  const foundBrand = brandList.find((brand) => brand.id === brandId);
  return foundBrand || null;
};

/**
 * Селектор получения локализации бренда
 */
export const selectBrandLocale = () => (state: RootState) =>
  state.productBase.brandLocale || ({} as LocaleDTO);

/**
 * Селектор получения списка линеек продуктов по id бренда
 *
 * @param brandId id бренда
 */
export const selectProductLineListByBrandId =
  (brandId: number | null | undefined) => (state: RootState) =>
    brandId ? state.productBase.productLineList[brandId] || [] : [];

/**
 * Селектор получения линейки продукта по id линейки продукта и бренда
 *
 * @param productLineId id линейки продукта
 * @param brandId id бренда
 */
export const selectProductLineById =
  (productLineId: number, brandId: number) => (state: RootState) => {
    const productLineList = state.productBase.productLineList[brandId];
    const foundProductLine = productLineList.find(
      (productLine) => productLineId === productLine.id,
    );
    return foundProductLine || null;
  };

/**
 * Селектор получения локализации линейки продукта
 */
export const selectProductLineLocale = () => (state: RootState) =>
  state.productBase.productLineLocale || ({} as LocaleDTO);

/**
 * Селектор получения списка категорий контейнеров
 */
export const selectCellCategoryList = () => (state: RootState) =>
  state.productBase.cellCategoryList;

/**
 * Селектор получения категории контейнера по id
 *
 * @param cellCategoryId id категории контейнера
 */
export const selectCellCategoryById =
  (cellCategoryId: number | undefined) => (state: RootState) => {
    const cellCategoryList = state.productBase.cellCategoryList;
    const foundCellCategory = cellCategoryList.find(({ id }) => id === cellCategoryId);
    return foundCellCategory || null;
  };

/**
 * Селектор получения локализации категории контейнера
 */
export const selectCellCategoryLocale = () => (state: RootState) =>
  state.productBase.cellCategoryLocale || ({} as LocaleDTO);

/**
 * Селектор получения единицы измерения категории контейнера
 */
export const selectCellCategoryUnit = () => (state: RootState) =>
  state.productBase.cellCategoryList.reduce(
    (acc, item) => {
      acc[item.id] = item.unit;
      return acc;
    },
    {} as Record<number, string>,
  );

/**
 * Селектор получения списка назначений контейнеров
 */
export const selectCellPurposeList = () => (state: RootState) => state.productBase.cellPurposeList;

/**
 * Селектор получения назначения контейнера по id
 *
 * @param cellPurposeId id назначения контейнера
 */
export const selectCellPurposeById = (cellPurposeId: number | undefined) => (state: RootState) => {
  const cellPurposeList = state.productBase.cellPurposeList;
  const foundCellPurpose = cellPurposeList.find(({ id }) => id === cellPurposeId);
  return foundCellPurpose || null;
};

/**
 * Селектор получения флагов показа вкуса и вида спорт пита по id назначения контейнера
 */
export const selectFlagCellPurposeById =
  (cellPurposeId: number | undefined) => (state: RootState) => {
    const cellPurpose = cellPurposeId && selectCellPurposeById(cellPurposeId)(state);
    const { tasted, sportPit } = cellPurpose || { tasted: false, sportPit: false };

    return { tasted, sportPit };
  };

/**
 * Селектор получения локализации назначения контейнера
 */
export const selectCellPurposeLocale = () => (state: RootState) =>
  state.productBase.cellPurposeLocale;

/**
 * Селектор получения списка вкусов
 */
export const selectTasteList = () => (state: RootState) => state.productBase.tasteList;

/**
 * Селектор получения вкуса по id
 *
 * @param tasteId id вкуса
 */
export const selectTasteById = (tasteId: number) => (state: RootState) => {
  const tasteList = state.productBase.tasteList;
  const foundTaste = tasteList.find((taste) => taste.id === tasteId);
  return foundTaste || null;
};

/**
 * Селектор получения локализации вкуса
 */
export const selectTasteLocale = () => (state: RootState) => state.productBase.tasteLocale;

/**
 * Селектор получения списка видов
 */
export const selectCellViewList = () => (state: RootState) => state.productBase.cellViewList;

/**
 * Селектор получения вида  по id
 *
 * @param sportPitId id вида
 */
export const selectCellViewById = (sportPitId: number) => (state: RootState) => {
  const sportPitList = state.productBase.cellViewList;
  const foundSportPit = sportPitList.find((sportPit) => sportPit.id === sportPitId);
  return foundSportPit || null;
};

/**
 * Селектор получения локализации вида
 */
export const selectCellViewLocale = () => (state: RootState) => state.productBase.cellViewLocale;

/**
 * Селектор получения списка возможных компонентов состава
 */
export const selectComponentList = () => (state: RootState) => state.productBase.componentList;

/**
 * Селектор получения возможного компонента состава по id
 *
 * @param componentId id компонента состава
 */
export const selectComponentById = (componentId: number) => (state: RootState) => {
  const componentList = state.productBase.componentList;
  const foundComponent = componentList.find((component) => component.id === componentId);
  return foundComponent || null;
};

/**
 * Селектор получения локализации возможного компонента состава
 */
export const selectComponentLocale = () => (state: RootState) => state.productBase.componentLocale;

/**
 * Селектор получения списка продуктов
 *
 * @param productLineId id линейки продуктов
 */
export const selectProductListByProductLineId =
  (productLineId: number | undefined) => (state: RootState) =>
    productLineId ? state.productBase.product.list?.[productLineId] : null;

/**
 * Селектор получения списка продуктов без информации и загрузки
 *
 * @param productLineId id линейки продуктов
 */
export const selectProductListByProductLineIdState =
  (productLineId: number | null | undefined) => (state: RootState) =>
    productLineId ? state.productBase.product.list?.[productLineId]?.state || [] : [];

/**
 * Селектор получения детальной информации о продукте
 */
export const selectProduct = () => (state: RootState) => state.productBase.product.details;

/**
 * Селектор получения локализации продукта
 */
export const selectProductLocale = () => (state: RootState) => state.productBase.product.locale;

/**
 * Селектор получения списка рецептов
 */
export const selectRecipeList = () => (state: RootState) => state.productBase.recipe.list;

/**
 * Селектор получения детальной информации о рецепте
 */
export const selectRecipeDetails = () => (state: RootState) => state.productBase.recipe.details;

/**
 * Селектор получение локализации рецепта
 */
export const selectRecipeLocale = () => (state: RootState) => state.productBase.recipe.locale;
