import { FC } from 'react';
import TableCard from '../../../../../../components/TableCard';
import { ConnectionStatus, StatusColor } from '../../../../../../types/serverInterface/machineDTO';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import { Text, TextPropView } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../../components/HorizontalContainer';
import classNames from 'classnames';
import styles from './MachineListInOutlet.module.scss';
import MachineListInOutletStorageCell from './MachineListInOutletStorageCell';
import { MachineListItem } from '../../../../../../types/serverInterface/machineListDTO';
import { useNavigate } from 'react-router-dom';

/**
 * Получение статуса
 *
 * @param color статус
 */
const getStatus = (color: StatusColor): TextPropView => {
  switch (color) {
    case StatusColor.ERROR:
      return 'alert';
    case StatusColor.WARNING:
      return 'warning';
    case StatusColor.SUCCESS:
      return 'success';
  }
  return 'alert';
};

const getConnectionStatusClass = (connectionStatus: ConnectionStatus) => {
  switch (connectionStatus) {
    case ConnectionStatus.ONLINE:
      return styles.connectionStatus_online;
    case ConnectionStatus.OFFLINE:
      return styles.connectionStatus_offline;
  }
};

/**
 * Свойства компонента MachineListInOutlet
 */
type MachineListInGroupProps = {
  /**
   * Список автоматов торговой точки
   */
  machineList: MachineListItem[];
};

/**
 * Список автоматов торговой точки
 */
const MachineListInOutlet: FC<MachineListInGroupProps> = ({ machineList }) => {
  const navigate = useNavigate();

  // Обработчики
  const handleRowClick = ({ id }: MachineListItem) => {
    navigate(`/machineControl/outlet/machine/${id}`);
  };

  // render методы
  const renderNameCell = ({ name, serialNumber, connectionStatus, model }: MachineListItem) => (
    <VerticalContainer space="2xs">
      <HorizontalContainer space="xs" align="center">
        <div
          className={classNames(
            styles.connectionStatus,
            getConnectionStatusClass(connectionStatus),
          )}
        />
        <Text weight="semibold">{name}</Text>
      </HorizontalContainer>
      <HorizontalContainer space="xs" align="center">
        <HorizontalContainer space="xs">
          <Text weight="semibold" view="secondary">
            {model.name}
          </Text>
          <Text view="secondary" size="m">
            {serialNumber}
          </Text>
        </HorizontalContainer>
      </HorizontalContainer>
    </VerticalContainer>
  );

  const renderSalesCell = ({ salePeriodResult }: MachineListItem) => (
    <VerticalContainer space="2xs">
      {/*<Text weight="semibold">0</Text>*/}
      <Text weight="semibold" size="m">
        {salePeriodResult?.qty}
      </Text>
    </VerticalContainer>
  );

  const renderStorageCell = (machine: MachineListItem) => (
    <MachineListInOutletStorageCell machineCells={machine} />
  );

  const renderStatusCell = ({ status }: MachineListItem) => (
    <VerticalContainer space="2xs" align="end">
      <Text weight="semibold" view={getStatus(status.color)}>
        {status.text}
      </Text>
      <Text view="secondary" size="m">
        {status.date}
      </Text>
    </VerticalContainer>
  );

  return (
    <TableCard
      className={styles.table}
      rows={machineList}
      withHeader={false}
      onRowClick={handleRowClick}
      columns={{
        name: {
          key: 'name',
          title: '',
          alignment: 'left',
          renderCell: renderNameCell,
        },
        salePeriodResult: {
          key: 'salePeriodResult',
          title: '',
          sortable: false,
          alignment: 'left',
          renderCell: renderSalesCell,
        },
        cells: {
          key: 'cells',
          title: '',
          sortable: false,
          alignment: 'left',
          renderCell: renderStorageCell,
        },
        status: {
          key: 'status',
          title: '',
          sortable: false,
          alignment: 'right',
          renderCell: renderStatusCell,
        },
      }}
    />
  );
};

export default MachineListInOutlet;
