import { LeftMenuItemGroupList } from './type';
import { defaultIconProps } from '../../consts/defaultIconProps';
import styles from './LeftMenu.module.scss';
import { IconAutomat } from '../../assets/icon/iconAutomat';
import { IconShakerTag } from '../../assets/icon/iconShakerTag';
import { IconFormula } from '../../assets/icon/iconFormula';
import { IconCompass } from '../../assets/icon/iconCompass';
import classNames from 'classnames';
import { Roles } from '../../types/serverInterface/cabinetDTO';
import { IconPriceLabel } from '@consta/uikit/IconPriceLabel';

const iconGroupProps = {
  ...defaultIconProps,
  className: classNames(styles.icon, !(window.innerWidth > 1800) && styles.isLaptop),
  size: 'm' as const,
};

const iconItemProps = {
  ...defaultIconProps,
  className: classNames(!(window.innerWidth > 1800) && styles.isLaptop, styles.itemIcon),
  size: 's' as const,
};

export const leftMenuGroupList: LeftMenuItemGroupList = [
  {
    label: 'Организация',
    renderIcon: () => <IconCompass {...iconGroupProps} />,
    listItems: [
      {
        label: 'components.leftMenu.organizations',
        path: '/organization/list',
        requiredOrganization: false,
        role: [Roles.ROOT, Roles.MANUFACTURER],
        renderIcon: () => <IconCompass {...iconItemProps} />,
      },
      {
        label: 'Сотрудники',
        path: `/organization/members/list`,
        requiredOrganization: true,
        role: [Roles.ROOT, Roles.ORGANIZATION_OWNER, Roles.ORGANIZATION_MEMBER],
        renderIcon: () => <IconCompass {...iconItemProps} />,
      },
    ],
  },
  {
    label: 'База товаров',
    renderIcon: () => <IconFormula {...iconGroupProps} />,
    listItems: [
      {
        label: 'components.leftMenu.productBase',
        path: '/productBase/list',
        requiredOrganization: false,
        role: [
          Roles.ROOT,
          Roles.ORGANIZATION_MEMBER,
          Roles.ORGANIZATION_OWNER,
          Roles.MANUFACTURER,
          Roles.DEVELOPER,
        ],
        renderIcon: () => <IconFormula {...iconItemProps} />,
      },
      {
        label: 'components.leftMenu.productBaseConfig',
        path: '/productBaseConfig',
        requiredOrganization: false,
        role: [Roles.ROOT, Roles.DEVELOPER],
        renderIcon: () => <IconFormula {...iconItemProps} />,
      },
    ],
  },
  {
    label: 'Автоматы',
    renderIcon: () => <IconAutomat {...iconGroupProps} />,
    listItems: [
      {
        label: 'components.leftMenu.model',
        path: '/machineControl/model',
        requiredOrganization: false,
        role: [Roles.ROOT, Roles.DEVELOPER],
        renderIcon: () => <IconAutomat {...iconItemProps} />,
      },
      {
        label: 'components.leftMenu.machineList',
        fullLabel: 'Автоматы',
        path: '/machineControl/machine',
        requiredOrganization: true,
        role: [
          Roles.ROOT,
          Roles.ORGANIZATION_MEMBER,
          Roles.ORGANIZATION_OWNER,
          Roles.MANUFACTURER,
          // Roles.DEVELOPER,
        ],
        renderIcon: () => <IconAutomat {...iconItemProps} />,
      },
      {
        label: 'Торговые точки',
        path: `/store/list`,
        requiredOrganization: true,
        role: [Roles.ROOT, Roles.ORGANIZATION_OWNER, Roles.ORGANIZATION_MEMBER],
        renderIcon: () => <IconPriceLabel {...iconItemProps} />,
      },
    ],
  },
  {
    label: 'Промокоды',
    renderIcon: () => <IconShakerTag {...iconGroupProps} />,
    listItems: [
      {
        label: 'Промокоды',
        path: `/promoCode/list`,
        requiredOrganization: true,
        role: [Roles.ROOT, Roles.ORGANIZATION_OWNER, Roles.ORGANIZATION_MEMBER],
        renderIcon: () => <IconShakerTag {...iconItemProps} />,
      },
    ],
  },
];
