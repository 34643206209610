import { AbstractApiModule } from '../../abstractApiModule';
import {
  BrandDTO,
  CreateBrandDTO,
  EditBrandDTO,
  ProductFilters,
} from '../../../../types/serverInterface/brandDTO';
import { LocaleDTO } from '../../../../types/serverInterface/localeDTO';
import {
  CreateProductLineDTO,
  EditProductLineDTO,
  ProductLineDTO,
} from '../../../../types/serverInterface/productLineDTO';
import {
  CellCategoryRes,
  CreateCellCategoryReq,
  EditCellCategoryReq,
} from '../../../../types/serverInterface/cellCategoryDTO';
import { CreateTasteDTO, EditTasteDTO, TasteDTO } from '../../../../types/serverInterface/tasteDTO';
import {
  CreateCellViewDTO,
  EditCellViewDTO,
  CellViewDTO,
} from '../../../../types/serverInterface/cellViewDTO';
import {
  ComponentDTO,
  CreateComponentDTO,
  EditComponentDTO,
} from '../../../../types/serverInterface/componentDTO';
import {
  CreateProductDTO,
  EditProductCalibrationReq,
  EditProductCharacteristicsDTO,
  EditProductComponentDTO,
  EditProductDTO,
  ProductDetailsRes,
  ProductListItemDTO,
} from '../../../../types/serverInterface/productDTO';
import {
  CreateRecipeDTO,
  EditRecipeBasic,
  Ingredient,
  RecipeDetailsDTO,
  RecipeDTO,
} from '../../../../types/serverInterface/recipeDTO';
import {
  CellPurposeRes,
  CreateCellPurposeReq,
  EditCellPurposeReq,
} from '../../../../types/serverInterface/cellPurpose';
import { ProductBaseItemDTO } from '../../../../types/serverInterface/promoCodeDTO';

import { productBaseUrl } from '../../../../consts';

const getFilterParams = ({
  cellPurposeId,
  organizationId,
  sportPitId,
  tasteId,
  cellCategoryId,
}: ProductFilters) => {
  const queryParams = [
    organizationId !== undefined && organizationId !== null
      ? `organizationId=${organizationId}`
      : null,
    cellCategoryId !== undefined && cellCategoryId !== null
      ? `cellCategoryId=${cellCategoryId}`
      : null,
    cellPurposeId !== undefined && cellPurposeId !== null ? `cellPurposeId=${cellPurposeId}` : null,
    tasteId !== undefined && tasteId !== null ? `tasteId=${tasteId}` : null,
    sportPitId !== undefined && sportPitId !== null ? `sportPitId=${sportPitId}` : null,
  ]
    .filter((param) => param !== null)
    .join('&');

  return `${queryParams ? '?' + queryParams : ''}`;
};

export class ProductBaseModule extends AbstractApiModule {
  /**
   * Изменение локализации
   *
   * @param data данные формы изменения локализации
   */
  editLocale(data: LocaleDTO) {
    return this.request.post(`${productBaseUrl}/locale/create`, data);
  }

  // TODO: добавить выбранный язык в запрос
  /**
   * Получения списка брендов
   */
  fetchBrandList(filters: ProductFilters) {
    return this.request.get<void, BrandDTO[]>(
      `${productBaseUrl}/brand/list${getFilterParams(filters)}`,
    );
  }

  /**
   * Создание бренда
   *
   * @param data данные формы создания бренда
   */
  createBrand(data: CreateBrandDTO): Promise<void> {
    return this.request.post<CreateBrandDTO, void>(`${productBaseUrl}/brand/create`, data);
  }

  /**
   * Изменение бренда
   *
   * @param data данные формы изменения бренда
   */
  editBrand(data: EditBrandDTO) {
    return this.request.patch(`${productBaseUrl}/brand/edit/${data.id}`, data);
  }

  /**
   * Удаление бренда
   *
   * @param brandId id бренда
   */
  deleteBrand(brandId: number): Promise<void> {
    return this.request.delete(`${productBaseUrl}/brand/${brandId}`);
  }

  /**
   * Получение локализации бренда
   *
   * @param brandId id бренда
   */
  getBrandLocale(brandId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/brand/locale/${brandId}`);
  }

  /**
   * Получение списка линеек продукта в бренде
   *
   * @param brandId id бренда
   * @param filters фильтры списка
   */
  fetchProductLineList(brandId: number, filters: ProductFilters) {
    return this.request.get<void, ProductLineDTO[]>(
      `${productBaseUrl}/ingredient-line/list/${brandId}${getFilterParams(filters)}`,
    );
  }

  /**
   * Создание линейки продуктов
   *
   * @param data данные формы создания линейки продуктов
   */
  createProductLine(data: CreateProductLineDTO): Promise<void> {
    return this.request.post<CreateProductLineDTO, void>(
      `${productBaseUrl}/ingredient-line/create`,
      data,
    );
  }

  /**
   * Изменение линейки продукта
   *
   * @param data данные формы изменения линейки продукта
   */
  editProductLine(data: EditProductLineDTO): Promise<void> {
    return this.request.patch(`${productBaseUrl}/ingredient-line/edit/${data.id}`, data);
  }

  /**
   * Удаление линейки продуктов
   *
   * @param productLineId id линейки продуктов
   */
  deleteProductLine(productLineId: number): Promise<void> {
    return this.request.delete(`${productBaseUrl}/ingredient-line/${productLineId}`);
  }

  /**
   * Получение локализации линейки продуктов
   *
   * @param productLineId id линейки продуктов
   */
  getProductLineLocale(productLineId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/ingredient-line/locale/${productLineId}`);
  }

  /**
   * Получение списка категорий контейнера
   */
  fetchCellCategoryList(): Promise<CellCategoryRes[]> {
    return this.request.get(`${productBaseUrl}/cell-category/list`);
  }

  /**
   * Получение категории контейнера
   *
   * @param cellCategoryId id категории контейнера
   */
  fetchCellCategoryById(cellCategoryId: number): Promise<CellCategoryRes> {
    return this.request.get(`${productBaseUrl}/cell-category/element/${cellCategoryId}`);
  }

  /**
   * Создание категории контейнера
   *
   * @param data данные формы созданя категории контейнера
   */
  createCellCategory(data: CreateCellCategoryReq) {
    return this.request.post(`${productBaseUrl}/cell-category/create`, data);
  }

  /**
   * Изменение категории контейнера
   *
   * @param data данные формы изменения категории контейнера
   * @param cellCategoryId id категории контейнера
   */
  editCellCategory(data: EditCellCategoryReq, cellCategoryId: number) {
    return this.request.patch(`${productBaseUrl}/cell-category/edit/${cellCategoryId}`, data);
  }

  /**
   * Удаление категории контейнера
   *
   * @param cellCategoryId id категории контейнера
   */
  deleteCellCategory(cellCategoryId: number) {
    return this.request.delete(`${productBaseUrl}/cell-category/${cellCategoryId}`);
  }

  /**
   * Получение локализации категории контейнера
   *
   * @param cellCategoryId id категории контейнера
   */
  getCellCategoryLocale(cellCategoryId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/cell-category/locale/${cellCategoryId}`);
  }

  /**
   * Получение списка назначений контейнера
   */
  fetchCellPurposeList(): Promise<CellPurposeRes[]> {
    return this.request.get(`${productBaseUrl}/cell-purpose/list`);
  }

  /**
   * Получение назначения контейнера по id
   *
   * @param cellPurposeId id назначения контейнера
   */
  fetchCellPurposeById(cellPurposeId: number): Promise<CellPurposeRes> {
    return this.request.get(`${productBaseUrl}/cell-purpose/element/${cellPurposeId}`);
  }

  /**
   * Создание назначения контейнера
   *
   * @param data данные формы создания назначения контейнера
   */
  createCellPurpose(data: CreateCellPurposeReq) {
    return this.request.post(`${productBaseUrl}/cell-purpose/create`, data);
  }

  /**
   * Изменение назначения контейнера
   *
   * @param data данные формы изменнения назначения контейнера
   * @param cellPurposeId id назначения контейнера
   */
  editCellPurpose(data: EditCellPurposeReq, cellPurposeId: number) {
    return this.request.patch(`${productBaseUrl}/cell-purpose/edit/${cellPurposeId}`, data);
  }

  /**
   * Удаление назначения контейнера
   *
   * @param cellPurposeId id назначения контейнера
   */
  deleteCellPurpose(cellPurposeId: number) {
    return this.request.delete(`${productBaseUrl}/cell-purpose/${cellPurposeId}`);
  }

  /**
   * Получение списка локализаций назначения контейнера
   *
   * @param cellPurposeId id назначения контейнера
   */
  fetchCellPurposeLocaleById(cellPurposeId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/cell-purpose/locale/${cellPurposeId}`);
  }

  /**
   * Получение списка вкусов
   */
  fetchTasteList(): Promise<TasteDTO[]> {
    return this.request.get(`${productBaseUrl}/taste/list`);
  }

  /**
   * Получение вкуса по id
   *
   * @param tasteId id вкуса
   */
  fetchTasteById(tasteId: number): Promise<TasteDTO> {
    return this.request.get(`${productBaseUrl}/taste/element/${tasteId}`);
  }

  /**
   * Создание вкуса
   *
   * @param data данные формы создания вкуса
   */
  createTaste(data: CreateTasteDTO) {
    return this.request.post(`${productBaseUrl}/taste/create`, data);
  }

  /**
   * Изменение вкуса
   *
   * @param data данные формы изменения вкуса
   * @param tasteId id вкуса
   */
  editTaste(data: EditTasteDTO, tasteId: number) {
    return this.request.patch(`${productBaseUrl}/taste/edit/${tasteId}`, data);
  }

  /**
   * Удаление вкуса
   *
   * @param tasteId id вкуса
   */
  deleteTaste(tasteId: number) {
    return this.request.delete(`${productBaseUrl}/taste/${tasteId}`);
  }

  /**
   * Получение локализации по id вкуса
   *
   * @param tasteId id вкуса
   */
  fetchTasteLocaleById(tasteId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/taste/locale/${tasteId}`);
  }

  /**
   * Получение списка видов спортивного питания
   */
  fetchSportPitList(): Promise<CellViewDTO[]> {
    return this.request.get(`${productBaseUrl}/view/list`);
  }

  /**
   * Получение вида спортивного питания по id
   *
   * @param sportPitId id вида спортивного питания
   */
  fetchSportPitById(sportPitId: number): Promise<CellViewDTO> {
    return this.request.get(`${productBaseUrl}/view/element/${sportPitId}`);
  }

  /**
   * Создание вида спортивного питания
   *
   * @param data данные формы создания вида спортивного питания
   */
  createSportPit(data: CreateCellViewDTO) {
    return this.request.post(`${productBaseUrl}/view/create`, data);
  }

  /**
   * Изменение вида спортивного питания
   *
   * @param data данные формы изменения вида спортивного питания
   * @param sportPitId id вида спортивного питания
   */
  editSportPit(data: EditCellViewDTO, sportPitId: number) {
    return this.request.patch(`${productBaseUrl}/view/edit/${sportPitId}`, data);
  }

  /**
   * Удаление вида спортивного питания
   *
   * @param sportPitId id вида спортивного питания
   */
  deleteSportPit(sportPitId: number) {
    return this.request.delete(`${productBaseUrl}/view/${sportPitId}`);
  }

  /**
   * Получение локализации вида спортивного питания
   *
   * @param sportPitId id вида спортивного питания
   */
  fetchSportPitLocaleById(sportPitId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/view/locale/${sportPitId}`);
  }

  /**
   * Получение списка возможных компонентов состава
   */
  fetchComponentList(): Promise<ComponentDTO[]> {
    return this.request.get(`${productBaseUrl}/component/list`);
  }

  /**
   * Получение локализации возможного компонента состава
   *
   * @param componentId id компонента состава
   */
  fetchComponentLocaleById(componentId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/component/locale/${componentId}`);
  }

  /**
   * Создание возможного компонента состава
   *
   * @param data данные формы создания возможного компонента состава
   */
  createComponent(data: CreateComponentDTO) {
    return this.request.post(`${productBaseUrl}/component/create`, data);
  }

  /**
   * Изменения возможного компонента состава
   *
   * @param data даннные формы изменения возможного компонента состава
   * @param componentId id возможного компонента состава
   */
  editComponent(data: EditComponentDTO, componentId: number) {
    return this.request.patch(`${productBaseUrl}/component/edit/${componentId}`, data);
  }

  /**
   * Удаление возможного компонента состава
   *
   * @param componentId id возможного компонента состава
   */
  deleteComponent(componentId: number) {
    return this.request.delete(`${productBaseUrl}/component/${componentId}`);
  }

  /**
   * Получение списка продуктов в линейке продуктов
   *
   * @param productLineId id линейки продуктов
   * @param filters фильтр списка продуктов
   */
  fetchProductList(productLineId: number, filters: ProductFilters): Promise<ProductListItemDTO[]> {
    return this.request.get(
      `${productBaseUrl}/ingredient/list/${productLineId}${getFilterParams(filters)}`,
    );
  }

  /**
   * Получение детальной информации о продукте
   *
   * @param productId id продукта
   */
  fetchProductById(productId: number): Promise<ProductDetailsRes> {
    return this.request.get(`${productBaseUrl}/ingredient/element/${productId}`);
  }

  /**
   * Получение локализации продукта
   *
   * @param productId id продукта
   */
  fetchProductLocaleById(productId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/ingredient/locale/${productId}`);
  }

  /**
   * Создание продукта
   *
   * @param data данные формы создания продукта
   */
  createProduct(data: CreateProductDTO) {
    return this.request.post(`${productBaseUrl}/ingredient/create`, data);
  }

  /**
   * Изменение продукта
   *
   * @param data данные формы изменения продукта
   * @param productId id продукта
   */
  editProduct(data: EditProductDTO, productId: number) {
    return this.request.patch(`${productBaseUrl}/ingredient/edit/${productId}`, data);
  }

  /**
   * Удаление продукта
   *
   * @param productId id продукта
   */
  deleteProduct(productId: number) {
    return this.request.delete(`${productBaseUrl}/ingredient/${productId}`);
  }

  /**
   * Изменения состава продукта
   *
   * @param data данные формы изменения состава продукта
   * @param productId id продукта
   */
  editProductComponent(data: EditProductComponentDTO, productId: number) {
    return this.request.post(`${productBaseUrl}/ingredient-component/change/${productId}`, data);
  }

  /**
   * Изменение характеристик (тэгов) продукта
   *
   * @param data данные формы именения характеристик продукта
   * @param productId id продукта
   */
  editProductCharacteristics(data: EditProductCharacteristicsDTO, productId: number) {
    return this.request.post(`${productBaseUrl}/ingredient-characteristic/edit/${productId}`, data);
  }

  /**
   * Изменение калибровки родукта
   *
   * @param data данные формы изменения калибровки продукта
   * @param productId id продукта
   */
  editProductCalibration(data: EditProductCalibrationReq, productId: number) {
    return this.request.post(`${productBaseUrl}/ingredient-calibration/edit/${productId}`, data);
  }

  /**
   * Получение списка рецептов
   *
   * @param organizationId id организации
   */
  fetchRecipeList(organizationId: number | null): Promise<RecipeDTO[]> {
    return this.request.get(
      `${productBaseUrl}/recipe/list${organizationId ? `?organizationId=${organizationId}` : ''}`,
    );
  }

  /**
   * Получение детальной информации о рецепте
   *
   * @param recipeId id рецепта
   */
  fetchRecipeById(recipeId: number): Promise<RecipeDetailsDTO> {
    return this.request.get(`${productBaseUrl}/recipe/element/${recipeId}`);
  }

  /**
   * Получение локализации рецепта
   *
   * @param recipeId id рецепта
   */
  fetchRecipeLocale(recipeId: number): Promise<LocaleDTO> {
    return this.request.get(`${productBaseUrl}/recipe/locale/${recipeId}`);
  }

  /**
   * Создание рецепта
   *
   * @param data данные формы создания рецепта
   */
  createRecipe(data: CreateRecipeDTO) {
    return this.request.post(`${productBaseUrl}/recipe/create`, data);
  }

  /**
   * Изменение рецепта
   *
   * @param data данные формы изменения рецепта
   * @param recipeId id рецепта
   */
  editRecipe(data: EditRecipeBasic, recipeId: number) {
    return this.request.patch(`${productBaseUrl}/recipe/edit/${recipeId}`, data);
  }

  /**
   * Изменение ингредиентов рецепта
   *
   * @param data данные формы изменения ингредиентов рецепта
   * @param recipeId id рецепта
   */
  editRecipeIngredients(data: Ingredient[], recipeId: number) {
    return this.request.post(`${productBaseUrl}/recipe-param/change/${recipeId}`, data);
  }

  /**
   * Удаление рецепта
   *
   * @param recipeId id рецепта
   */
  deleteRecipe(recipeId: number) {
    return this.request.delete(`${recipeId}/recipe/${recipeId}`);
  }

  /**
   * Получение списка брендов с фильтром по категориям и видом
   *
   * @param categoryListId список категорий для фильтра
   * @param viewsListId список видов для списка
   */
  getBrandListByCategoryView(
    categoryListId: number[] | null,
    viewsListId: number[] | null,
  ): Promise<ProductBaseItemDTO[]> {
    return this.request.get(
      `${productBaseUrl}/brand/list/by-category-view-ids${
        categoryListId
          ? categoryListId.map((id, index) => `${index ? '&' : '?'}categoryIds=${id}`).join('')
          : ''
      }${viewsListId ? viewsListId.map((id, index) => `${index || categoryListId?.length ? '&' : '?'}viewIds=${id}`).join('') : ''}`,
    );
  }

  /**
   * Получение списка линеек продуктов с фильтром по категориям, видам и бренду
   *
   * @param categoryListId список категорий для фильтра
   * @param viewsListId список видов для списка
   * @param brandId id бренда
   */
  getIngredientLineListByCategoryView(
    categoryListId: number[] | null,
    viewsListId: number[] | null,
    brandId: number,
  ): Promise<ProductBaseItemDTO[]> {
    return this.request.get(
      `${productBaseUrl}/ingredient-line/list-for-promo-code/${brandId}${
        categoryListId
          ? categoryListId.map((id, index) => `${index ? '&' : '?'}categoryIds=${id}`).join('')
          : ''
      }${viewsListId ? viewsListId.map((id, index) => `${index || categoryListId?.length ? '&' : '?'}viewIds=${id}`).join('') : ''}`,
    );
  }

  /**
   * Получение списка продуктов с фильтром по категориям, видам и линейкам
   *
   * @param categoryListId
   * @param viewsListId
   * @param ingredientLineIds
   */
  getIngredientListByCategoryView(
    categoryListId: number[] | null,
    viewsListId: number[] | null,
    ingredientLineIds: number[],
  ): Promise<ProductBaseItemDTO[]> {
    return this.request.get(
      `${productBaseUrl}/ingredient/list-for-promo-code${
        ingredientLineIds
          ? ingredientLineIds
              .map(
                (id, index) =>
                  `${index || viewsListId?.length || categoryListId?.length ? '?' : '?'}ingredientIds=${id}`,
              )
              .join('')
          : ''
      }`,
    );
  }
}
