import { FC, useState } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import classNames from 'classnames';
import { getViewStorageItemClassName } from '../../../../../components/Machine/MachineStorage/helpers';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { TextField } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../../../helpers/ inputHelpers';
import styles from './MachinePricesTable.module.scss';
import { MachinePricesErrors } from '../MachinePrices';
import { Dosage, ProductGroup, ProductView } from '../../../../../types/serverInterface/storageDTO';
import {
  EditMachineCalibrationsDTO,
  MachinePriceInfo,
  MachinePricesInfoDTO,
} from '../../../../../types/serverInterface/machineDTO';
import TableCardWithBackground from '../../../../../components/TableCardWithBackground/TableCardWithBackground';
import { DisplayType, displayTypeValue } from '../../../../App';
import { Checkbox } from '@consta/uikit/Checkbox';
import MachineProductCalibrationModal from './MachineProductCalibrationModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconFilter } from '../../../../../assets/icon/iconFilter';
import { CustomFormType } from '../../../../../types/serverInterface/customForm';

/**
 * Свойства компонента MachinePricesTable
 */
type MachinePricesTableProps = {
  /**
   * id автомата
   */
  machineId: number;
  /**
   * Список товаров и цен
   */
  machinePrices: MachinePricesInfoDTO;
  /**
   * Калибровка
   */
  calibrations: EditMachineCalibrationsDTO;
  /**
   * Ошибки валидации
   */
  errors: MachinePricesErrors;
  /**
   * Включен режим редактирования
   */
  isEdit: boolean;
  /**
   * Обработчик изменения дозировки
   */
  onDosageChange: (
    rowIndex: number,
  ) => (dosageIndex: number) => (key: keyof Dosage) => (value: number | null) => void;
  /**
   * Обработчик изменения флага блокировки
   */
  onIsBlockChange: (rowIndex: number) => (dosageIndex: number) => (checked: boolean) => void;
  /**
   * Обработчик изменения конфигурации
   */
  onConfigChange: ({
    purposeConfig,
    categoryConfig,
  }: {
    categoryConfig: CustomFormType;
    purposeConfig: CustomFormType;
  }) => (ingredientId: number) => void;
};

/**
 * Информация о товарах автомата
 */
const MachinePricesTable: FC<MachinePricesTableProps> = ({
  machineId,
  machinePrices,
  isEdit,
  errors,
  calibrations,
  onDosageChange,
  onIsBlockChange,
  onConfigChange,
}) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { prices, qtyDosage } = machinePrices;

  const [selectedIngredient, setSelectedIngredient] = useState<{
    ingredientName: string;
    ingredientId: number;
    cellNumber: number;
    cellCategoryName: string;
  } | null>(null);

  // Обработчики
  const handleDosageChange =
    (rowIndex: number) =>
    (dosageIndex: number) =>
    (key: keyof Dosage) =>
    ({ value }: { value: string | null }) => {
      onDosageChange(rowIndex)(dosageIndex)(key)(value ? Number(value) : null);
    };

  const handleIsBlockChange =
    (rowIndex: number) =>
    (dosageIndex: number) =>
    ({ checked }: { checked: boolean }) => {
      onIsBlockChange(rowIndex)(dosageIndex)(!checked);
    };

  // render методы
  const renderDosageInfoCell = (data: Dosage) => {
    return (
      <div className={styles.dosageCell}>
        <Text size={isLaptop ? 'm' : 'l'}>{data?.volume ? `${data?.volume} мл:` : ''}</Text>
        <Text size={isLaptop ? 'm' : 'l'}>{data?.price ? `${data?.price} ₽` : ''}</Text>
      </div>
    );
  };

  const renderDosageFormCell = (data: Dosage, rowIndex: number, dosageIndex: number) => {
    const error = errors?.[rowIndex]?.[dosageIndex];

    const isBlock = !!dosageIndex && data?.isBlock !== false;

    return (
      <div className={styles.dosageCell}>
        {!!dosageIndex && (
          <Checkbox checked={!isBlock} onChange={handleIsBlockChange(rowIndex)(dosageIndex)} />
        )}
        <FieldGroup className={styles.fieldGroup} size={isLaptop ? 's' : 'm'}>
          <TextField
            disabled={isBlock}
            value={getInputNumberValue(data?.volume)}
            type="number"
            incrementButtons={false}
            rightSide="мл"
            status={error?.volume?.status}
            onChange={handleDosageChange(rowIndex)(dosageIndex)('volume')}
            onDrop={(e) => {
              e.preventDefault();
            }}
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
          <TextField
            disabled={isBlock}
            value={getInputNumberValue(data?.price)}
            type="number"
            incrementButtons={false}
            rightSide="₽"
            status={error?.price?.status}
            onChange={handleDosageChange(rowIndex)(dosageIndex)('price')}
            onDrop={(e) => {
              e.preventDefault();
            }}
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
        </FieldGroup>
      </div>
    );
  };

  const renderDosageCell = (data: Dosage, rowIndex: number, dosageIndex: number) => {
    if (isEdit) {
      return renderDosageFormCell(data, rowIndex, dosageIndex);
    }

    return renderDosageInfoCell(data);
  };

  const renderCellNumbersCell = (data: MachinePriceInfo) => (
    <div className={styles.cellNumbersCell}>
      {data.cell.map(({ number, view }) => (
        <div
          key={number}
          className={classNames(
            styles.cellNumberCard,
            getViewStorageItemClassName(ProductGroup.POWDER, view),
          )}
        >
          <Text weight="semibold" className={styles.text}>
            {number}
          </Text>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <TableCardWithBackground
        rows={prices}
        columns={{
          cell: {
            key: 'cell',
            // sortable: false,
            title: '№',
            alignment: 'left',
            width: '1%',
            // className: styles.cellNumbersWrapper,
            renderCell: renderCellNumbersCell,
          },
          brandName: {
            key: 'brandName',
            // sortable: true,
            title: 'Бренд',
            alignment: 'left',
            width: '17%',
            getItemLabel: ({ brandName }: MachinePriceInfo) => brandName,
          },
          productLineName: {
            key: 'productLineName',
            // sortable: true,
            title: 'Линейка',
            alignment: 'left',
            width: '20%',
            getItemLabel: ({ productLineName }: MachinePriceInfo) => productLineName,
          },
          productName: {
            key: 'productName',
            // sortable: true,
            title: 'Вкус',
            alignment: 'left',
            width: '27%',
            getItemLabel: ({ productName }: MachinePriceInfo) => productName,
          },
          prices: {
            key: 'prices',
            title: 'Дозировка',
            // sortable: false,
            alignment: 'left',
            // суммарно 35%
            width: `${35 / qtyDosage}%`,
            multi: true,
            count: qtyDosage,
            renderMultiCell: renderDosageCell,
          },
          ingredientId: {
            key: 'ingredientId',
            title: '',
            alignment: 'left',
            width: '5%',
            renderCell: ({ ingredientId, brandName, productLineName, productName, cell }) =>
              isEdit ? (
                <Button
                  onlyIcon
                  iconLeft={IconFilter as any}
                  view="clear"
                  onClick={() =>
                    setSelectedIngredient({
                      ingredientId: ingredientId,
                      ingredientName: `${brandName} ${productLineName} ${productName}`,
                      cellNumber: cell[0].number,
                      cellCategoryName:
                        cell[0].view === ProductView.PRIMARY ? 'Порошок' : 'Концентрат',
                    })
                  }
                />
              ) : (
                <></>
              ),
          },
        }}
      />
      {selectedIngredient && (
        <MachineProductCalibrationModal
          cellCategoryName={selectedIngredient.cellCategoryName}
          calibrations={calibrations}
          machineId={machineId}
          ingredientId={selectedIngredient.ingredientId}
          ingredientName={selectedIngredient.ingredientName}
          cellNumber={selectedIngredient.cellNumber}
          onClose={() => setSelectedIngredient(null)}
          onSubmit={onConfigChange}
        />
      )}
    </>
  );
};

export default MachinePricesTable;
