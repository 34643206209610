import { BrandDTO } from '../../types/serverInterface/brandDTO';
import { createSlice, isRejected } from '@reduxjs/toolkit';
import {
  createBrandThunk,
  createComponentThunk,
  createCellCategoryThunk,
  createCellPurposeThunk,
  createProductLineThunk,
  createProductThunk,
  createRecipeThunk,
  createSportPitThunk,
  createTasteThunk,
  deleteBrandThunk,
  deleteComponentThunk,
  deleteCellCategoryThunk,
  deleteCellPurposeThunk,
  deleteProductLineThunk,
  deleteProductThunk,
  deleteRecipeThunk,
  deleteSportPitThunk,
  deleteTasteThunk,
  editBrandThunk,
  editComponentThunk,
  editCellCategoryThunk,
  editCellPurposeThunk,
  editLocaleThunk,
  editProductCalibrationThunk,
  editProductCharacteristicsThunk,
  editProductComponentThunk,
  editProductLineThunk,
  editProductThunk,
  editRecipeIngredientsThunk,
  editRecipeThunk,
  editSportPitThunk,
  editTasteThunk,
  getBrandListThunk,
  getBrandLocaleThunk,
  getComponentListThunk,
  getComponentLocaleByIdThunk,
  getCellCategoryByIdThunk,
  getCellCategoryListThunk,
  getCellCategoryLocaleByIdThunk,
  getCellPurposeByIdThunk,
  getCellPurposeListThunk,
  getCellPurposeLocaleByIdThunk,
  getProductByIdThunk,
  getProductLineListThunk,
  getProductLineLocaleThunk,
  getProductListThunk,
  getProductLocaleByIdThunk,
  getRecipeByIdThunk,
  getRecipeListThunk,
  getRecipeLocaleThunk,
  getSportPitByIdThunk,
  getSportPitListThunk,
  getSportPitLocaleByIdThunk,
  getTasteByIdThunk,
  getTasteListThunk,
  getTasteLocaleByIdThunk,
} from './thunk';
import { errorHandler, NotificationType } from '../handlers';
import { LocaleDTO } from '../../types/serverInterface/localeDTO';
import { ProductLineDTO } from '../../types/serverInterface/productLineDTO';
import { CellCategoryDTO } from '../../types/serverInterface/cellCategoryDTO';
import { CellPurposeDTO } from '../../types/serverInterface/cellPurpose';
import { TasteDTO } from '../../types/serverInterface/tasteDTO';
import { CellViewDTO } from '../../types/serverInterface/cellViewDTO';
import { ComponentDTO } from '../../types/serverInterface/componentDTO';
import { ProductDetailsDTO, ProductListItemDTO } from '../../types/serverInterface/productDTO';
import { RecipeDetailsDTO, RecipeDTO } from '../../types/serverInterface/recipeDTO';

type StateItemType<T> = {
  state: T extends [] ? T : T | null;
  isLoading: boolean;
  isReject: boolean;
};

export type ProductBaseState = {
  brandList: BrandDTO[];
  brandLocale: LocaleDTO | null;
  productLineList: Record<number, ProductLineDTO[]>;
  productLineLocale: LocaleDTO | null;
  cellCategoryList: CellCategoryDTO[];
  cellCategoryLocale: LocaleDTO | null;
  cellPurposeList: CellPurposeDTO[];
  cellPurposeLocale: LocaleDTO | null;
  tasteList: TasteDTO[];
  tasteLocale: LocaleDTO | null;
  cellViewList: CellViewDTO[];
  cellViewLocale: LocaleDTO | null;
  componentList: ComponentDTO[];
  componentLocale: LocaleDTO | null;

  product: {
    list: Record<number, StateItemType<ProductListItemDTO[]>> | null;
    details: StateItemType<ProductDetailsDTO>;
    locale: StateItemType<LocaleDTO>;
  };
  recipe: {
    list: StateItemType<RecipeDTO[]>;
    details: StateItemType<RecipeDetailsDTO>;
    locale: StateItemType<LocaleDTO>;
  };
  notifications: NotificationType[];
};

const initialState: ProductBaseState = {
  brandList: [],
  brandLocale: null,
  productLineList: {},
  productLineLocale: null,
  cellCategoryList: [],
  cellCategoryLocale: null,
  cellPurposeList: [],
  cellPurposeLocale: null,
  tasteList: [],
  tasteLocale: null,
  cellViewList: [],
  cellViewLocale: null,
  componentList: [],
  componentLocale: null,

  product: {
    list: null,
    details: {
      state: null,
      isLoading: false,
      isReject: false,
    },
    locale: {
      state: null,
      isLoading: false,
      isReject: false,
    },
  },
  recipe: {
    list: {
      state: [],
      isLoading: false,
      isReject: false,
    },
    details: {
      state: null,
      isLoading: false,
      isReject: false,
    },
    locale: {
      state: null,
      isLoading: false,
      isReject: false,
    },
  },

  notifications: [],
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: ProductBaseState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const productBaseSlice = createSlice({
  name: 'productBase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getBrandList
    builder.addCase(getBrandListThunk.fulfilled, (state, action) => {
      state.brandList = action.payload;
    });

    //  getBrandLocale
    builder.addCase(getBrandLocaleThunk.fulfilled, (state, action) => {
      state.brandLocale = action.payload;
    });

    // getProductLineList
    builder.addCase(getProductLineListThunk.fulfilled, (state, action) => {
      const { brandId } = action.meta.arg;
      state.productLineList[brandId] = action.payload;
    });

    // getProductLineLocale
    builder.addCase(getProductLineLocaleThunk.fulfilled, (state, action) => {
      state.productLineLocale = action.payload;
    });

    // getCellCategoryList
    builder.addCase(getCellCategoryListThunk.fulfilled, (state, action) => {
      state.cellCategoryList = action.payload;
    });

    //  getCellCategoryLocaleById
    builder.addCase(getCellCategoryLocaleByIdThunk.fulfilled, (state, action) => {
      state.cellCategoryLocale = action.payload;
    });

    // getCellPurposeList
    builder.addCase(getCellPurposeListThunk.fulfilled, (state, action) => {
      state.cellPurposeList = action.payload;
    });

    //  getCellPurposeLocaleById
    builder.addCase(getCellPurposeLocaleByIdThunk.fulfilled, (state, action) => {
      state.cellPurposeLocale = action.payload;
    });

    // getTasteList
    builder.addCase(getTasteListThunk.fulfilled, (state, action) => {
      state.tasteList = action.payload;
    });

    // getTasteLocaleById
    builder.addCase(getTasteLocaleByIdThunk.fulfilled, (state, action) => {
      state.tasteLocale = action.payload;
    });

    // getSportPitList
    builder.addCase(getSportPitListThunk.fulfilled, (state, action) => {
      state.cellViewList = action.payload;
    });

    // getSportPitLocaleById
    builder.addCase(getSportPitLocaleByIdThunk.fulfilled, (state, action) => {
      state.cellViewLocale = action.payload;
    });

    // getComponentList
    builder.addCase(getComponentListThunk.fulfilled, (state, action) => {
      state.componentList = action.payload;
    });

    // getComponentLocaleById
    builder.addCase(getComponentLocaleByIdThunk.fulfilled, (state, action) => {
      state.componentLocale = action.payload;
    });

    // getProductList
    builder.addCase(getProductListThunk.pending, (state, action) => {
      const { productLineId } = action.meta.arg;
      state.product.list = {
        ...state.product.list,
        [productLineId]: {
          state: [],
          isLoading: true,
          isReject: false,
        },
      };
    });

    builder.addCase(getProductListThunk.rejected, (state, action) => {
      const { productLineId } = action.meta.arg;

      state.product.list = {
        ...state.product.list,
        [productLineId]: {
          state: [],
          isLoading: false,
          isReject: true,
        },
      };
    });

    builder.addCase(getProductListThunk.fulfilled, (state, action) => {
      const { productLineId } = action.meta.arg;

      state.product.list = {
        ...state.product.list,
        [productLineId]: {
          state: action.payload,
          isLoading: false,
          isReject: false,
        },
      };
    });

    // getProductById
    builder.addCase(getProductByIdThunk.pending, (state) => {
      state.product.details.state = null;
      state.product.details.isLoading = true;
      state.product.details.isReject = false;
    });

    builder.addCase(getProductByIdThunk.rejected, (state) => {
      state.product.details.isLoading = false;
      state.product.details.isReject = true;
    });

    builder.addCase(getProductByIdThunk.fulfilled, (state, action) => {
      state.product.details.state = action.payload;
      state.product.details.isLoading = false;
    });

    // getProductLocaleById
    builder.addCase(getProductLocaleByIdThunk.pending, (state) => {
      state.product.locale.state = null;
      state.product.locale.isLoading = true;
      state.product.locale.isReject = false;
    });

    builder.addCase(getProductLocaleByIdThunk.rejected, (state) => {
      state.product.locale.isLoading = false;
      state.product.locale.isReject = true;
    });

    builder.addCase(getProductLocaleByIdThunk.fulfilled, (state, action) => {
      state.product.locale.state = action.payload;
      state.product.locale.isLoading = false;
    });

    // getRecipeList
    builder.addCase(getRecipeListThunk.pending, (state) => {
      state.recipe.list.state = [];
      state.recipe.list.isLoading = true;
      state.recipe.list.isReject = false;
    });

    builder.addCase(getRecipeListThunk.rejected, (state) => {
      state.recipe.list.isLoading = false;
      state.recipe.list.isReject = true;
    });

    builder.addCase(getRecipeListThunk.fulfilled, (state, action) => {
      state.recipe.list.state = action.payload;
      state.recipe.list.isLoading = false;
    });

    // getRecipeById
    builder.addCase(getRecipeByIdThunk.pending, (state) => {
      state.recipe.details.state = null;
      state.recipe.details.isLoading = true;
      state.recipe.details.isReject = false;
    });

    builder.addCase(getRecipeByIdThunk.rejected, (state) => {
      state.recipe.details.isLoading = false;
      state.recipe.details.isReject = true;
    });

    builder.addCase(getRecipeByIdThunk.fulfilled, (state, action) => {
      state.recipe.details.state = action.payload;
      state.recipe.details.isLoading = false;
    });

    // getRecipeLocale
    builder.addCase(getRecipeLocaleThunk.pending, (state) => {
      state.recipe.locale.state = null;
      state.recipe.locale.isLoading = true;
      state.recipe.locale.isReject = false;
    });

    builder.addCase(getRecipeLocaleThunk.rejected, (state) => {
      state.recipe.locale.isLoading = false;
      state.recipe.locale.isReject = true;
    });

    builder.addCase(getRecipeLocaleThunk.fulfilled, (state, action) => {
      state.recipe.locale.state = action.payload;
      state.recipe.locale.isLoading = false;
    });

    builder.addMatcher(
      isRejected(
        createBrandThunk,
        createComponentThunk,
        createCellCategoryThunk,
        createCellPurposeThunk,
        createProductLineThunk,
        createProductThunk,
        createRecipeThunk,
        createSportPitThunk,
        createTasteThunk,
        deleteBrandThunk,
        deleteComponentThunk,
        deleteCellCategoryThunk,
        deleteCellPurposeThunk,
        deleteProductLineThunk,
        deleteProductThunk,
        deleteRecipeThunk,
        deleteSportPitThunk,
        deleteTasteThunk,
        editBrandThunk,
        editComponentThunk,
        editCellCategoryThunk,
        editCellPurposeThunk,
        editLocaleThunk,
        editProductCalibrationThunk,
        editProductCharacteristicsThunk,
        editProductComponentThunk,
        editProductLineThunk,
        editProductThunk,
        editRecipeIngredientsThunk,
        editRecipeThunk,
        editSportPitThunk,
        editTasteThunk,
        getBrandListThunk,
        getBrandLocaleThunk,
        getComponentListThunk,
        getComponentLocaleByIdThunk,
        getCellCategoryByIdThunk,
        getCellCategoryListThunk,
        getCellCategoryLocaleByIdThunk,
        getCellPurposeByIdThunk,
        getCellPurposeListThunk,
        getCellPurposeLocaleByIdThunk,
        getProductByIdThunk,
        getProductLineListThunk,
        getProductLineLocaleThunk,
        getProductListThunk,
        getProductLocaleByIdThunk,
        getRecipeByIdThunk,
        getRecipeListThunk,
        getRecipeLocaleThunk,
        getSportPitByIdThunk,
        getSportPitListThunk,
        getSportPitLocaleByIdThunk,
        getTasteByIdThunk,
        getTasteListThunk,
        getTasteLocaleByIdThunk,
      ),
      (state, action) => {
        errorHandler(action)(addNotification(state));
      },
    );
  },
});

export const productBaseReducer = productBaseSlice.reducer;
