import { FC } from 'react';
import {
  CreateBaseModelDTO,
  MachineModelGroup,
  machineModelGroupList,
} from '../../../../../types/serverInterface/machineModelDTO';
import { useTranslation } from 'react-i18next';
import { getInputNumberValue } from '../../../../../helpers/ inputHelpers';
import { BaseModelError } from '../types';
import TextFieldWithTooltip from '../../../../../components/withTooltip/TextField';
import ComboboxWithTooltip from '../../../../../components/withTooltip/Combobox/ComboboxWithTooltip';

/**
 * Свойства компонента CreateModelBasicForm
 */
type CreateModelBasicFormProps = {
  /**
   * Значение формы
   */
  value: CreateBaseModelDTO;
  /**
   * Ошибки формы
   */
  error: BaseModelError;
  /**
   * Обработчки изменений данных в форме
   *
   * @param key ключ, какое поле меняется
   * @param value значение, на которое меняем
   */
  onChange: (key: keyof CreateBaseModelDTO) => (value: string | null) => void;
};

/**
 * Шаг с базовой онформацией в форме создания модели автомата
 */
const CreateModelBasicForm: FC<CreateModelBasicFormProps> = ({ value, error, onChange }) => {
  const { t } = useTranslation();

  const { name, groupModel, qtyDosage } = value;

  // Вспомогательные методы
  const getGroupModelItemLabel = (item: MachineModelGroup) =>
    t(`machineControl.model.create.basic.group.list.item.${item}`);

  const getGroupModelItemKey = (item: MachineModelGroup) => item;

  // Обработчики
  const handleChange =
    (key: keyof CreateBaseModelDTO) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  const handleGroupModelChange = ({ value }: { value: MachineModelGroup | null }) => {
    onChange('groupModel')(value);
  };

  return (
    <>
      <TextFieldWithTooltip
        tooltipProps={{
          content: !error.name?.isError
            ? t('machineControl.model.create.basic.name.tooltip.label')
            : t(String(error.name?.label)),
        }}
        status={!error ? undefined : error.name?.status}
        label={t('machineControl.model.create.basic.name.input.label')}
        width="full"
        value={name}
        onChange={handleChange('name')}
      />
      <ComboboxWithTooltip
        tooltipProps={{
          content: !error.groupModel?.isError
            ? t('machineControl.model.create.basic.group.tooltip.label')
            : t(String(error.groupModel?.label)),
        }}
        status={!error ? undefined : error.groupModel?.status}
        label={t('machineControl.model.create.basic.group.select.label')}
        items={machineModelGroupList}
        value={groupModel}
        getItemLabel={getGroupModelItemLabel}
        getItemKey={getGroupModelItemKey}
        onChange={handleGroupModelChange}
      />
      <TextFieldWithTooltip
        tooltipProps={{
          content: !error.qtyDosage?.isError
            ? t('machineControl.model.create.basic.maxVolume.tooltip.label')
            : t(String(error.qtyDosage?.label)),
        }}
        status={!error ? undefined : error.qtyDosage?.status}
        label={t('machineControl.model.create.basic.maxVolume.input.label')}
        width="full"
        type="number"
        value={getInputNumberValue(qtyDosage)}
        onChange={handleChange('qtyDosage')}
      />
    </>
  );
};

export default CreateModelBasicForm;
