import { createSlice, isRejected, PayloadAction } from '@reduxjs/toolkit';
import {
  createMachineThunk,
  deleteMachineThunk,
  editMachineThunk,
  editMachinePricesThunk,
  // getMachineFillingThunk,
  // getMachinePricesThunk,
  // getMachineListThunk,
  // getMachineThunk,
  refillMachineThunk,
  getMachineModelListThunk,
  getMachineModelDetailsThunk,
  getMachineBaseInfoThunk,
  getMachineHomePageInfoThunk,
  getMachineStorageInfoThunk,
  getMachinePricesInfoThunk,
  refillMachineStorageThunk,
  editMachineStorageThunk,
  editMachineBaseInfoThunk,
  getMachineSaLesListThunk,
  getMachineSaLesListQtyThunk,
  getMachineListByOrganizationIdThunk,
  getMachineSettingsThunk,
  getMachineSettingsActionsStatusThunk,
  sendMachineCustomActionThunk,
  editWaterCellThunk,
  getMachineSaLesListByPrintThunk,
  regenerateMachineRegistrationCodeThunk,
  toggleActiveMachineRegistrationCodeThunk,
  getMachineRegistrationCodeThunk,
  getIngredientCalibrationValueThunk,
  getMachineListWithFiltersThunk,
  disconnectMachineThunk,
  deleteOutletThunk,
  getOutletListThunk,
  getOutletListFiltersThunk,
  editOutletThunk,
  createOutletThunk,
  getOutletMachineIdsThunk,
} from './thunk';
import { errorHandler, NotificationType } from '../handlers';
import {
  MachineBaseInfoDTO,
  MachineHomeDTO,
  MachineHomeWaterCellDTO,
  MachineSmallListItemDTO,
  MachinePricesInfoDTO,
  MachineSalesRes,
  MachineSettingsDTO,
  MachineStorageDTO,
  MachineStorageInfoFormatted,
  MachineStorageInfoProductCellFormatted,
  MachineStorageInfoWaterCell,
  MachineStorageInfoWaterCellRes,
  SuitabilityContent,
} from '../../types/serverInterface/machineDTO';
import { MachinePricesDTO } from '../../types/serverInterface/storageDTO';
import {
  MachineModelDetailsDTO,
  MachineModelDTO,
} from '../../types/serverInterface/machineModelDTO';
import { MachineRegistrationCodeDTO } from '../../types/serverInterface/MachineRegistrationCodeDTO';
import { MachineIngredientCalibrationValueDTO } from '../../types/serverInterface/machineIngredientCalibrationValueDTO';
import { MachineListDTO } from '../../types/serverInterface/machineListDTO';
import {
  OutletFiltersInfoDTO,
  OutletListDTO,
  OutletMachineIdsDTO,
} from '../../types/serverInterface/outletDTO';
import { getModifiedArrayForFormattingProductCalibration } from '../../helpers/getModifiedArrayForFormattingProductCalibration';

type StateItemType<T> = {
  state: T extends [] ? T : T | null;
  isLoading: boolean;
  isReject: boolean;
};

// TODO: проверить неиспользуемые
export type MachineControlState = {
  registrationCode: StateItemType<MachineRegistrationCodeDTO>;
  modelList: StateItemType<MachineModelDTO[]>;
  modelDetails: StateItemType<MachineModelDetailsDTO>;
  machineSmallList: StateItemType<MachineSmallListItemDTO[]>;
  machineBase: StateItemType<MachineBaseInfoDTO>;
  machineHome: StateItemType<MachineHomeDTO>;
  machineStorageInfo: StateItemType<MachineStorageInfoFormatted>;
  machineNewStorageInfo: MachineStorageInfoFormatted | null;
  machinePricesInfo: StateItemType<MachinePricesInfoDTO>;
  machineFilling: StateItemType<MachineStorageDTO>;
  machinePrices: StateItemType<MachinePricesDTO>;
  machineSales: StateItemType<MachineSalesRes>;
  machineSettings: StateItemType<MachineSettingsDTO>;
  ingredientCalibrationValue: StateItemType<MachineIngredientCalibrationValueDTO>;
  machineSalesQty: number;
  notifications: NotificationType[];
  machineList: StateItemType<MachineListDTO>;
  outletList: StateItemType<OutletListDTO>;
  outletFilters: StateItemType<OutletFiltersInfoDTO>;
  outletMachineIds: StateItemType<OutletMachineIdsDTO>;
};

const initialState: MachineControlState = {
  registrationCode: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  modelList: {
    state: [],
    isLoading: false,
    isReject: false,
  },
  modelDetails: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineSmallList: {
    state: [],
    isLoading: false,
    isReject: false,
  },
  machineBase: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineHome: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineStorageInfo: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineNewStorageInfo: null,
  machinePricesInfo: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineFilling: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machinePrices: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineSales: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineSalesQty: 0,
  machineSettings: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  ingredientCalibrationValue: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineList: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  outletList: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  outletFilters: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  outletMachineIds: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  notifications: [],
};

const initialWaterValue: MachineStorageInfoWaterCell = {
  tapId: 0,
  id: 0,
  cellNumber: 0,
  expirationTimer: 0,
  filterMaxVolume: 0,
  filterTimer: 0,
  filterVolume: 0,
  isCount: false,
  volume: 0,
  maxVolume: 0,
  minVolume: 0,
  suitabilityContent: SuitabilityContent.SUCCESS,
};

const transformWaterCell = (
  resCells: MachineStorageInfoWaterCellRes[],
): MachineStorageInfoWaterCell[] => [
  resCells.reduce((accumulator, currentValue) => {
    const {
      cellNumber,
      isActive,
      id,
      volume,
      maxVolume,
      minVolume,
      expirationTimer,
      filterValue,
      suitabilityContent,
    } = currentValue;

    if (currentValue.isCount) {
      return {
        ...accumulator,
        cellNumber,
        isCount: isActive,
        suitabilityContent,
        id,
        maxVolume: Number((maxVolume / 1000).toFixed(1)),
        minVolume: Number((minVolume / 1000).toFixed(1)),
        volume: Number((volume / 1000).toFixed(1)),
        expirationTimer,
      };
    }

    return {
      ...accumulator,
      suitabilityContent,
      tapId: id,
      filterTimer: expirationTimer,
      filterMaxVolume: Number((filterValue / 1000).toFixed(1)),
      filterVolume: Number((volume / 1000).toFixed(1)),
    };
  }, initialWaterValue),
];

const initialWaterValueByHomePage: MachineHomeWaterCellDTO = {
  id: 0,
  cellNumber: 0,
  maxVolume: 0,
  minVolume: 0,
  volume: 0,
  isActive: false,
  isCount: false,
  suitabilityContent: SuitabilityContent.SUCCESS,
  filterValue: 0,
  expirationTimer: 0,
};

const transformWaterCellByHomePage = (
  resCells: MachineStorageInfoWaterCellRes[],
): MachineHomeWaterCellDTO[] => [
  resCells.reduce((accumulator, currentValue) => {
    const {
      cellNumber,
      isActive,
      id,
      volume,
      maxVolume,
      minVolume,
      expirationTimer,
      filterValue,
      suitabilityContent,
    } = currentValue;

    if (currentValue.isCount) {
      return {
        ...accumulator,
        cellNumber,
        isCount: isActive,
        suitabilityContent,
        id,
        maxVolume: Number((maxVolume / 1000).toFixed(1)),
        minVolume: Number((minVolume / 1000).toFixed(1)),
        volume: Number((volume / 1000).toFixed(1)),
        expirationTimer,
      };
    }

    return {
      ...accumulator,
      suitabilityContent,
      tapId: id,
      filterTimer: expirationTimer,
      filterMaxVolume: filterValue,
      filterVolume: volume,
    };
  }, initialWaterValueByHomePage),
];

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: MachineControlState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const machineControlSlice = createSlice({
  name: 'machineControl',
  initialState,
  reducers: {
    editNewStorage(state, action: PayloadAction<MachineStorageInfoFormatted | null>) {
      state.machineNewStorageInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getMachineModelListThunk
    builder.addCase(getMachineModelListThunk.pending, (state) => {
      state.modelList.isLoading = true;
      state.modelList.isReject = false;
    });

    builder.addCase(getMachineModelListThunk.rejected, (state) => {
      state.modelList.isLoading = false;
      state.modelList.isReject = true;
    });

    builder.addCase(getMachineModelListThunk.fulfilled, (state, action) => {
      state.modelList.state = action.payload;
      state.modelList.isLoading = false;
    });

    //getMachineModelThunk
    builder.addCase(getMachineModelDetailsThunk.pending, (state) => {
      state.modelDetails.state = null;
      state.modelDetails.isLoading = true;
      state.modelDetails.isReject = false;
    });

    builder.addCase(getMachineModelDetailsThunk.rejected, (state) => {
      state.modelDetails.isLoading = false;
      state.modelDetails.isReject = true;
    });

    builder.addCase(getMachineModelDetailsThunk.fulfilled, (state, action) => {
      state.modelDetails.state = action.payload;
      state.modelDetails.isLoading = false;
    });

    // getMachineListThunk
    // builder.addCase(getMachineListThunk.pending, (state) => {
    //   // state.machineList.state = [];
    //   state.machineList.isLoading = true;
    //   state.machineList.isReject = false;
    // });
    //
    // builder.addCase(getMachineListThunk.rejected, (state) => {
    //   state.machineList.state = [];
    //   state.machineList.isLoading = false;
    //   state.machineList.isReject = true;
    // });
    //
    // builder.addCase(getMachineListThunk.fulfilled, (state, action) => {
    //   state.machineList.state = action.payload;
    //   state.machineList.isLoading = false;
    // });

    //getMachineListByOrganizationIdThunk
    builder.addCase(getMachineListByOrganizationIdThunk.pending, (state) => {
      // state.machineSmallList.state = [];
      state.machineSmallList.isLoading = true;
      state.machineSmallList.isReject = false;
    });

    builder.addCase(getMachineListByOrganizationIdThunk.rejected, (state) => {
      state.machineSmallList.state = [];
      state.machineSmallList.isLoading = false;
      state.machineSmallList.isReject = true;
    });

    builder.addCase(getMachineListByOrganizationIdThunk.fulfilled, (state, action) => {
      state.machineSmallList.state = action.payload;
      state.machineSmallList.isLoading = false;
    });

    // // getMachineThunk
    // builder.addCase(getMachineThunk.pending, (state) => {
    //   state.machineDetails.state = null;
    //   state.machineDetails.isLoading = true;
    //   state.machineDetails.isReject = false;
    // });
    //
    // builder.addCase(getMachineThunk.rejected, (state) => {
    //   state.machineDetails.isLoading = false;
    //   state.machineDetails.isReject = true;
    // });
    //
    // builder.addCase(getMachineThunk.fulfilled, (state, action) => {
    //   state.machineDetails.state = action.payload;
    //   state.machineDetails.isLoading = false;
    // });
    //
    // // getMachineFillingThunk
    // builder.addCase(getMachineFillingThunk.pending, (state) => {
    //   state.machineFilling.state = null;
    //   state.machineFilling.isLoading = true;
    //   state.machineFilling.isReject = false;
    // });
    //
    // builder.addCase(getMachineFillingThunk.rejected, (state) => {
    //   state.machineFilling.isLoading = false;
    //   state.machineFilling.isReject = true;
    // });
    //
    // builder.addCase(getMachineFillingThunk.fulfilled, (state, action) => {
    //   state.machineFilling.state = action.payload;
    //   state.machineFilling.isLoading = false;
    // });
    //
    // // getMachinePricesThunk
    // builder.addCase(getMachinePricesThunk.pending, (state) => {
    //   state.machinePrices.state = null;
    //   state.machinePrices.isLoading = true;
    //   state.machinePrices.isReject = false;
    // });
    //
    // builder.addCase(getMachinePricesThunk.rejected, (state) => {
    //   state.machinePrices.isLoading = false;
    //   state.machinePrices.isReject = true;
    // });
    //
    // builder.addCase(getMachinePricesThunk.fulfilled, (state, action) => {
    //   state.machinePrices.state = action.payload;
    //   state.machinePrices.isLoading = false;
    // });

    // getMachineBaseInfoThunk
    builder.addCase(getMachineBaseInfoThunk.pending, (state) => {
      // state.machineBase.state = null;
      state.machineBase.isLoading = true;
      state.machineBase.isReject = false;
    });

    builder.addCase(getMachineBaseInfoThunk.rejected, (state) => {
      state.machineBase.isLoading = false;
      state.machineBase.isReject = true;
    });

    builder.addCase(getMachineBaseInfoThunk.fulfilled, (state, action) => {
      state.machineBase.state = action.payload;
      state.machineBase.isLoading = false;
    });

    // getMachineHomePageInfoThunk
    builder.addCase(getMachineHomePageInfoThunk.pending, (state) => {
      state.machineHome.state = null;
      state.machineHome.isLoading = true;
      state.machineHome.isReject = false;
    });

    builder.addCase(getMachineHomePageInfoThunk.rejected, (state) => {
      state.machineHome.isLoading = false;
      state.machineHome.isReject = true;
    });

    builder.addCase(getMachineHomePageInfoThunk.fulfilled, (state, action) => {
      state.machineHome.state = {
        ...action.payload,
        cellWaters: transformWaterCellByHomePage(action.payload.cellWaters),
      };
      state.machineHome.isLoading = false;
    });

    // getMachineStorageInfoThunk
    builder.addCase(getMachineStorageInfoThunk.pending, (state) => {
      state.machineStorageInfo.state = null;
      state.machineStorageInfo.isLoading = true;
      state.machineStorageInfo.isReject = false;
    });

    builder.addCase(getMachineStorageInfoThunk.rejected, (state) => {
      state.machineStorageInfo.isLoading = false;
      state.machineStorageInfo.isReject = true;
    });

    builder.addCase(getMachineStorageInfoThunk.fulfilled, (state, action) => {
      state.machineStorageInfo.state = {
        ...action.payload,
        cellWaters: transformWaterCell(action.payload.cellWaters),
        cells: action.payload.cells.map(
          ({
            id,
            isActive,
            cellNumber,
            cellCategoryId,
            cellPurposeId,
            ingredientCell,
            productLineName,
            productName,
            brandName,
            group,
            maxVolume,
            minVolume,
            volume,
            view,
            expirationTimer,
          }): MachineStorageInfoProductCellFormatted => {
            // TODO: позже выпилить это
            return {
              id,
              cellNumber,
              maxVolume,
              minVolume,
              volume,
              brandName,
              ingredientLineName: productLineName,
              ingredientName: productName,
              group,
              view,
              expirationTimer,
              cellCategoryId,
              cellPurposeId,
              isActive,
              brandId: ingredientCell ? ingredientCell.brandId : null,
              ingredientLineId: ingredientCell ? ingredientCell.ingredientLineId : null,
              ingredientId: ingredientCell ? ingredientCell.ingredientId : null,
              isActiveProduct: ingredientCell ? ingredientCell.isActive : null,
            };
          },
        ),
      };
      state.machineStorageInfo.isLoading = false;
    });

    // getMachinePricesInfoThunk
    builder.addCase(getMachinePricesInfoThunk.pending, (state) => {
      state.machinePricesInfo.state = null;
      state.machinePricesInfo.isLoading = true;
      state.machinePricesInfo.isReject = false;
    });

    builder.addCase(getMachinePricesInfoThunk.rejected, (state) => {
      state.machinePricesInfo.isLoading = false;
      state.machinePricesInfo.isReject = true;
    });

    builder.addCase(getMachinePricesInfoThunk.fulfilled, (state, action) => {
      state.machinePricesInfo.state = {
        ...action.payload,
        prices: action.payload.prices.map((price) => ({
          ...price,
          prices: price.prices.map((price) => ({ ...price, isBlock: !price.volume })),
        })),
      };
      state.machinePricesInfo.isLoading = false;
    });

    // getMachineSaLesList
    builder.addCase(getMachineSaLesListThunk.pending, (state) => {
      // state.machineSales.state = null;
      state.machineSales.isLoading = true;
      state.machineSales.isReject = false;
    });

    builder.addCase(getMachineSaLesListThunk.rejected, (state) => {
      state.machineSales.isLoading = false;
      state.machineSales.isReject = true;
    });

    builder.addCase(getMachineSaLesListThunk.fulfilled, (state, action) => {
      state.machineSales.state = action.payload;
      state.machineSales.isLoading = false;
    });

    // getMachineSaLesListQty
    builder.addCase(getMachineSaLesListQtyThunk.pending, (state) => {
      state.machineSalesQty = 0;
    });

    builder.addCase(getMachineSaLesListQtyThunk.rejected, (state) => {
      state.machineSalesQty = 0;
    });

    builder.addCase(getMachineSaLesListQtyThunk.fulfilled, (state, action) => {
      state.machineSalesQty = action.payload.qty;
    });

    // getMachineSettingsThunk
    builder.addCase(getMachineSettingsThunk.pending, (state) => {
      state.machineSettings.isLoading = true;
      state.machineSettings.isReject = false;
    });

    builder.addCase(getMachineSettingsThunk.rejected, (state) => {
      state.machineSettings.isLoading = false;
      state.machineSettings.isReject = true;
    });

    builder.addCase(getMachineSettingsThunk.fulfilled, (state, action) => {
      state.machineSettings.state = {
        ...action.payload,
        action: JSON.parse(action.payload.action),
        cellWaters: action.payload.cellWaters.map((cell) => ({
          ...cell,
          filterValue: Number((cell.filterValue / 1000).toFixed(1)),
          maxVolume: Number((cell.maxVolume / 1000).toFixed(1)),
        })),
      };
      state.machineSettings.isLoading = false;
    });

    // getMachineRegistrationCodeThunk
    builder.addCase(getMachineRegistrationCodeThunk.pending, (state) => {
      state.registrationCode.isLoading = true;
      state.registrationCode.isReject = false;
    });

    builder.addCase(getMachineRegistrationCodeThunk.rejected, (state) => {
      state.registrationCode.isLoading = false;
      state.registrationCode.isReject = true;
    });

    builder.addCase(getMachineRegistrationCodeThunk.fulfilled, (state, action) => {
      state.registrationCode.state = action.payload;
      state.registrationCode.isLoading = false;
    });

    // regenerateMachineRegistrationCodeThunk
    builder.addCase(regenerateMachineRegistrationCodeThunk.pending, (state) => {
      state.registrationCode.isLoading = true;
      state.registrationCode.isReject = false;
    });

    builder.addCase(regenerateMachineRegistrationCodeThunk.rejected, (state) => {
      state.registrationCode.isLoading = false;
      state.registrationCode.isReject = true;
    });

    builder.addCase(regenerateMachineRegistrationCodeThunk.fulfilled, (state, action) => {
      state.registrationCode.state = { ...action.payload, isActive: true };
      state.registrationCode.isLoading = false;
    });

    // getIngredientCalibrationValueThunk
    builder.addCase(getIngredientCalibrationValueThunk.pending, (state) => {
      state.ingredientCalibrationValue.isLoading = true;
      state.ingredientCalibrationValue.isReject = false;
    });

    builder.addCase(getIngredientCalibrationValueThunk.rejected, (state) => {
      state.ingredientCalibrationValue.isLoading = false;
      state.ingredientCalibrationValue.isReject = true;
    });

    builder.addCase(getIngredientCalibrationValueThunk.fulfilled, (state, action) => {
      const { purposeConfigMachine, categoryConfigMachine } = action.payload;
      const { cellCategoryName } = action.meta.arg;

      state.ingredientCalibrationValue.state = {
        ...action.payload,
        categoryConfigMachine: getModifiedArrayForFormattingProductCalibration(
          categoryConfigMachine ? JSON.parse(categoryConfigMachine) : [],
          cellCategoryName,
        ),
        purposeConfigMachine: getModifiedArrayForFormattingProductCalibration(
          purposeConfigMachine ? JSON.parse(purposeConfigMachine) : [],
          cellCategoryName,
        ),
      };
      state.ingredientCalibrationValue.isLoading = false;
    });

    // getMachineListWithFiltersThunk
    builder.addCase(getMachineListWithFiltersThunk.pending, (state) => {
      state.machineList.isLoading = true;
      state.machineList.isReject = false;
    });

    builder.addCase(getMachineListWithFiltersThunk.rejected, (state) => {
      state.machineList.isLoading = false;
      state.machineList.isReject = true;
    });

    builder.addCase(getMachineListWithFiltersThunk.fulfilled, (state, action) => {
      state.machineList.state = {
        ...action.payload,
        results: action.payload.results.map((machine) => ({
          ...machine,
          cellWaters: machine.cellWaters.map((cellWater) => ({
            ...cellWater,
            volume: Number((cellWater.volume / 1000).toFixed(1)),
          })),
        })),
      };
      state.machineList.isLoading = false;
    });

    // getOutletListThunk
    builder.addCase(getOutletListThunk.pending, (state, action) => {
      state.outletList.isLoading = true;
      state.outletList.isReject = false;
    });

    builder.addCase(getOutletListThunk.rejected, (state, action) => {
      state.outletList.isLoading = false;
      state.outletList.isReject = true;
    });

    builder.addCase(getOutletListThunk.fulfilled, (state, action) => {
      state.outletList.isLoading = false;
      state.outletList.state = action.payload;
    });

    // getOutletListFiltersThunk
    builder.addCase(getOutletListFiltersThunk.pending, (state, action) => {
      state.outletFilters.isLoading = true;
      state.outletFilters.isReject = false;
    });

    builder.addCase(getOutletListFiltersThunk.rejected, (state, action) => {
      state.outletFilters.isLoading = false;
      state.outletFilters.isReject = true;
    });

    builder.addCase(getOutletListFiltersThunk.fulfilled, (state, action) => {
      state.outletFilters.isLoading = false;
      state.outletFilters.state = action.payload;
    });

    //getOutletMachineIdsThunk
    builder.addCase(getOutletMachineIdsThunk.pending, (state, action) => {
      state.outletMachineIds.isLoading = true;
      state.outletMachineIds.isReject = false;
    });

    builder.addCase(getOutletMachineIdsThunk.rejected, (state, action) => {
      state.outletMachineIds.isLoading = false;
      state.outletMachineIds.isReject = true;
    });

    builder.addCase(getOutletMachineIdsThunk.fulfilled, (state, action) => {
      state.outletMachineIds.isLoading = false;
      state.outletMachineIds.state = action.payload;
    });

    builder.addMatcher(
      isRejected(
        createMachineThunk,
        deleteMachineThunk,
        editMachineThunk,
        editMachinePricesThunk,
        getMachineListByOrganizationIdThunk,
        refillMachineThunk,
        getMachineBaseInfoThunk,
        getMachineHomePageInfoThunk,
        getMachineStorageInfoThunk,
        getMachinePricesInfoThunk,
        refillMachineStorageThunk,
        editMachinePricesThunk,
        editMachineStorageThunk,
        editMachineBaseInfoThunk,
        getMachineSaLesListThunk,
        getMachineSaLesListQtyThunk,
        getMachineSettingsActionsStatusThunk,
        getMachineSettingsThunk,
        sendMachineCustomActionThunk,
        editWaterCellThunk,
        getMachineSaLesListByPrintThunk,
        regenerateMachineRegistrationCodeThunk,
        toggleActiveMachineRegistrationCodeThunk,
        getMachineRegistrationCodeThunk,
        getIngredientCalibrationValueThunk,
        getMachineListWithFiltersThunk,
        disconnectMachineThunk,
        deleteOutletThunk,
        getOutletListThunk,
        getOutletListFiltersThunk,
        editOutletThunk,
        createOutletThunk,
        getOutletMachineIdsThunk,
      ),
      (state, action) => {
        errorHandler(action)(addNotification(state));
      },
    );
  },
});

export const machineControlReducer = machineControlSlice.reducer;

export const { editNewStorage } = machineControlSlice.actions;
