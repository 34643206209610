import {
  CreateBaseModelDTO,
  CreateCellsModelDTO,
  CreateCupModelCell,
  CreateDisposableModelCell,
  CreateProductModelCell,
  CreateWaterModelCell,
} from '../../../../types/serverInterface/machineModelDTO';
import {
  BaseModelError,
  CellsModelError,
  CupCellsFieldError,
  DisposableCellsFieldError,
  ProductCellsFieldError,
  WaterCellsFieldError,
} from './types';
import { Dispatch, SetStateAction } from 'react';
import {
  cupInitialError,
  disposableInitialError,
  formCellsInitialError,
  formBasicInitialError,
  productInitialError,
  waterInitialError,
  cellNumberUniqueFieldError,
  nameUniqueFieldError,
  cupValueUniqueFieldError,
} from './consts';
import {
  initialFieldError,
  requiredValidate,
  successFieldValidate,
} from '../../../../helpers/validateHelpers';

/**
 * Валидация ячейки продукта
 *
 * @param cell ячейка продукта
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateProductCell = (
  cell: CreateProductModelCell,
  setIsValid?: (isValid: boolean) => void,
): ProductCellsFieldError => {
  const { cellNumber, group, view, maxVolume, minVolume, expirationTimer, cellCategoryId } = cell;

  const productError: ProductCellsFieldError = {
    ...productInitialError,
    cellNumber: requiredValidate(cellNumber, setIsValid),
    cellCategoryId: requiredValidate(cellCategoryId, setIsValid),
    cellPurposeId: successFieldValidate,
    group: requiredValidate(group, setIsValid),
    view: requiredValidate(view, setIsValid),
    maxVolume: requiredValidate(maxVolume, setIsValid),
    minVolume: requiredValidate(minVolume, setIsValid),
    expirationTimer: requiredValidate(expirationTimer, setIsValid),
  };

  return { ...productError };
};

/**
 * Валидация ячейки воды
 *
 * @param cell ячейка воды
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateWaterCell = (
  cell: CreateWaterModelCell,
  setIsValid?: (isValid: boolean) => void,
): WaterCellsFieldError => {
  const { cellNumber, maxVolume, minVolume, expirationTimer, isCount, filterValue } = cell;

  let waterError: WaterCellsFieldError = {
    ...waterInitialError,
    cellNumber: requiredValidate(cellNumber, setIsValid),
    expirationTimer: requiredValidate(expirationTimer, setIsValid),
  };

  if (isCount) {
    waterError = {
      ...waterError,
      maxVolume: requiredValidate(maxVolume, setIsValid),
      minVolume: requiredValidate(minVolume, setIsValid),
    };
  } else {
    waterError = {
      ...waterError,
      filterValue: requiredValidate(filterValue, setIsValid),
    };
  }

  return { ...waterError };
};

/**
 * Валидация ячейки стакана
 *
 * @param cell ячейка стакана
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateCupCell = (
  cell: CreateCupModelCell,
  setIsValid?: (isValid: boolean) => void,
): CupCellsFieldError => {
  const { cellNumber, maxVolume, minVolume, cupVolume } = cell;

  const cupError: CupCellsFieldError = {
    ...cupInitialError,
    cupVolume: requiredValidate(cupVolume, setIsValid),
    minVolume: requiredValidate(minVolume, setIsValid),
    maxVolume: requiredValidate(maxVolume, setIsValid),
    cellNumber: requiredValidate(cellNumber, setIsValid),
  };

  return { ...cupError };
};

/**
 * Валидация ячейки расходника
 *
 * @param cell ячейка расходника
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateDisposableCell = (
  cell: CreateDisposableModelCell,
  setIsValid?: (isValid: boolean) => void,
): DisposableCellsFieldError => {
  const { cellNumber, minVolume, name } = cell;

  const disposableError: DisposableCellsFieldError = {
    ...disposableInitialError,
    cellNumber: requiredValidate(cellNumber, setIsValid),
    minVolume: requiredValidate(minVolume, setIsValid),
    name: requiredValidate(name, setIsValid),
  };

  return { ...disposableError };
};

/**
 * Валидация ячеек продуктов
 *
 * @param cells ячейки продуктов
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateProductCells = (
  cells: CreateProductModelCell[],
  setIsValid?: (isValid: boolean) => void,
): Record<number, ProductCellsFieldError> => {
  const errorMap: Record<number, ProductCellsFieldError> = {};

  cells.forEach((cell, index) => {
    errorMap[index] = validateProductCell(cell, setIsValid);
  });

  return errorMap;
};

/**
 * Валидация ячеек воды
 *
 * @param cells ячейки воды
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateWaterCells = (
  cells: CreateWaterModelCell[],
  setIsValid?: (isValid: boolean) => void,
): Record<number, WaterCellsFieldError> => {
  const errorMap: Record<number, WaterCellsFieldError> = {};

  cells.forEach((cell, index) => {
    errorMap[index] = validateWaterCell(cell, setIsValid);
  });

  return errorMap;
};

/**
 * Валидация ячеек стаканов
 *
 * @param cells ячейки стаканов
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateCupCells = (
  cells: CreateCupModelCell[],
  setIsValid?: (isValid: boolean) => void,
): Record<number, CupCellsFieldError> => {
  const errorMap: Record<number, CupCellsFieldError> = {};

  cells.forEach((cell, index) => {
    errorMap[index] = validateCupCell(cell, setIsValid);
  });

  return errorMap;
};

/**
 * Валидация ячеек расходников
 *
 * @param cells ячейки расходников
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateDisposableCells = (
  cells: CreateDisposableModelCell[],
  setIsValid?: (isValid: boolean) => void,
): Record<number, DisposableCellsFieldError> => {
  const errorMap: Record<number, DisposableCellsFieldError> = {};

  cells.forEach((cell, index) => {
    errorMap[index] = validateDisposableCell(cell, setIsValid);
  });

  return errorMap;
};

/**
 * Проверка на дублирование нумеров контейнеров
 *
 * @param cells ячейки
 * @param error ошибки
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
export const validateCellsNumbers = (
  cells: CreateCellsModelDTO,
  error: CellsModelError,
  setIsValid?: (isValid: boolean) => void,
): CellsModelError => {
  const validateError: CellsModelError = { ...error };
  const usedNumbers: Set<number> = new Set();

  cells.cells.forEach((cell, index) => {
    if (usedNumbers.has(Number(cell.cellNumber))) {
      validateError.cell[index].cellNumber = cellNumberUniqueFieldError;
      setIsValid && setIsValid(false);
    } else {
      if (validateError.cell?.[index]?.cellNumber?.errorKey === 'cellNumberUniqueFieldError') {
        validateError.cell[index].cellNumber = initialFieldError;
      }

      usedNumbers.add(Number(cell.cellNumber));
      setIsValid && setIsValid(true);
    }
  });

  cells.cellWaters.forEach((cell, index) => {
    if (usedNumbers.has(Number(cell.cellNumber))) {
      validateError.waterCell[index].cellNumber = cellNumberUniqueFieldError;
      setIsValid && setIsValid(false);
    } else {
      if (validateError.waterCell?.[index]?.cellNumber?.errorKey === 'cellNumberUniqueFieldError') {
        validateError.waterCell[index].cellNumber = initialFieldError;
      }

      usedNumbers.add(Number(cell.cellNumber));
      setIsValid && setIsValid(true);
    }
  });

  cells.cellCups.forEach((cell, index) => {
    if (usedNumbers.has(Number(cell.cellNumber))) {
      validateError.cupCell[index].cellNumber = cellNumberUniqueFieldError;
      setIsValid && setIsValid(false);
    } else {
      if (validateError.cupCell?.[index]?.cellNumber?.errorKey === 'cellNumberUniqueFieldError') {
        validateError.cupCell[index].cellNumber = initialFieldError;
      }

      usedNumbers.add(Number(cell.cellNumber));
      setIsValid && setIsValid(true);
    }
  });

  cells.cellDisposables.forEach((cell, index) => {
    if (usedNumbers.has(Number(cell.cellNumber))) {
      validateError.disposableCell[index].cellNumber = cellNumberUniqueFieldError;
      setIsValid && setIsValid(false);
    } else {
      if (
        validateError.disposableCell?.[index]?.cellNumber?.errorKey === 'cellNumberUniqueFieldError'
      ) {
        validateError.disposableCell[index].cellNumber = initialFieldError;
      }

      usedNumbers.add(Number(cell.cellNumber));
      setIsValid && setIsValid(true);
    }
  });

  return { ...validateError };
};

/**
 * Проверка на дублирование название расходника
 *
 * @param cells ячейки
 * @param error ошибки
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
const validateName = (
  cells: CreateCellsModelDTO,
  error: CellsModelError,
  setIsValid?: (isValid: boolean) => void,
): CellsModelError => {
  const validateError: CellsModelError = { ...error };
  const usedNumbers: Set<string> = new Set();

  cells.cellDisposables.forEach((cell, index) => {
    if (usedNumbers.has(cell.name)) {
      validateError.disposableCell[index].name = nameUniqueFieldError;
      setIsValid && setIsValid(false);
    } else {
      usedNumbers.add(cell.name);
      setIsValid && setIsValid(true);
    }
  });

  return { ...validateError };
};

/**
 * Проверка на дублирование объёма стакана
 *
 * @param cells ячейки
 * @param error ошибки
 * @param setIsValid функция изменения наличия глобальной ошибки
 */
const validateCupValue = (
  cells: CreateCellsModelDTO,
  error: CellsModelError,
  setIsValid?: (isValid: boolean) => void,
): CellsModelError => {
  const validateError: CellsModelError = { ...error };
  const usedNumbers: Set<number> = new Set();

  cells.cellCups.forEach((cell, index) => {
    if (usedNumbers.has(Number(cell.cupVolume))) {
      validateError.cupCell[index].cupVolume = cupValueUniqueFieldError;
      setIsValid && setIsValid(false);
    } else {
      usedNumbers.add(Number(cell.cupVolume));
      setIsValid && setIsValid(true);
    }
  });

  return { ...validateError };
};

/**
 * Валидация ячеек модели автомата
 *
 * @param cells ячейки
 * @param setError метод перезапись ошибок
 */
export const validateCells = (
  cells: CreateCellsModelDTO,
  setError: Dispatch<SetStateAction<CellsModelError>>,
): boolean => {
  let isValid = true;
  const setIsValid = (isValidField: boolean) => {
    isValid = isValidField && isValid;
  };

  const errorMap: CellsModelError = {
    ...formCellsInitialError,
    cell: validateProductCells(cells.cells, setIsValid),
    waterCell: validateWaterCells(cells.cellWaters, setIsValid),
    cupCell: validateCupCells(cells.cellCups, setIsValid),
    disposableCell: validateDisposableCells(cells.cellDisposables, setIsValid),
  };

  validateCellsNumbers(cells, errorMap, setIsValid);
  validateCupValue(cells, errorMap, setIsValid);
  validateName(cells, errorMap, setIsValid);

  setError(errorMap);

  return isValid;
};

/**
 * Волидация базовой информации модели автомата
 *
 * @param form форма базовой информации
 * @param setError метод перезаписи ошибок
 */
export const validateBasic = (
  form: CreateBaseModelDTO,
  setError: Dispatch<SetStateAction<BaseModelError>>,
): boolean => {
  const { name, qtyDosage, groupModel } = form;
  let isValid = true;
  const setIsValid = (isValidField: boolean) => {
    isValid = isValidField && isValid;
  };

  const error: BaseModelError = {
    ...formBasicInitialError,
    groupModel: requiredValidate(groupModel, setIsValid),
    name: requiredValidate(name, setIsValid),
    qtyDosage: requiredValidate(qtyDosage, setIsValid),
  };

  setError(error);

  return isValid;
};
