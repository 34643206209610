import {
  MachineFiltersDTO,
  ShutdownReasonFilters,
} from '../../../../types/serverInterface/machineListDTO';
import { OutletFiltersDTO } from '../../../../types/serverInterface/outletDTO';

/**
 * Получение параметров списка автоматов
 *
 * @param filters фильтры списка автоматов
 */
export const getMachineListParameters = (filters: MachineFiltersDTO) => {
  const arr: string[] = [];

  for (const key in filters) {
    const value = filters[key as keyof MachineFiltersDTO];
    if ((value || value === false) && key !== 'organizationId') {
      arr.push(`${key}=${value}`);
    }
  }

  return `${filters.organizationId}${arr.length ? '?' + arr.join('&') : ''}`;
};

/**
 * Получение параметров отключения автомата от телеметрии
 *
 * @param parameters параметры
 */
export const getMachineDisconnectParameters = (parameters: {
  machineId: number;
  shutdownReason: ShutdownReasonFilters;
  isDropStock: boolean;
}) => {
  const arr: string[] = [];

  for (const key in parameters) {
    const value =
      parameters[
        key as keyof {
          shutdownReason: ShutdownReasonFilters;
          isDropStock: boolean;
        }
      ];
    if ((value || value === false) && key !== 'organizationId') {
      arr.push(`${key}=${value}`);
    }
  }

  return `${parameters.machineId}${arr.length ? '?' + arr.join('&') : ''}`;
};

/**
 * Получение параметров списка торговых точек
 *
 * @param organizationId id организации
 * @param filters фильтры списка
 */
export const getOutletListParameters = (
  organizationId: number,
  filters: OutletFiltersDTO & { isArchived: boolean },
) => {
  const arr: string[] = [];

  for (const key in filters) {
    const value = filters[key as keyof (OutletFiltersDTO & { isArchived: boolean })];

    if (value || value === false) {
      arr.push(`${key}=${value}`);
    }
  }

  return `${organizationId}${arr.length ? '?' + arr.join('&') : ''}`;
};
