import React, { FC, useState } from 'react';
import VerticalContainer from '../../../../../components/VerticalContainer';
import styles from '../OutletTable.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import classNames from 'classnames';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { StatusColor } from '../../../../../types/serverInterface/machineDTO';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';
import MachineListInOutlet from './MachineListInOutlet';
import { OutletListItem } from '../../../../../types/serverInterface/outletDTO';
import { MachineListItem } from '../../../../../types/serverInterface/machineListDTO';
import { IconEditSquare } from '../../../../../assets/icon/iconEditSquare';
import { IconTrash } from '../../../../../assets/icon/iconTrash';

/**
 * Свойства компонента OutletTableRow
 */
type MachineGroupTableRowProps = {
  /**
   * Торговая точка
   */
  outlet: OutletListItem;
  /**
   * Список автоматов торговой точки
   */
  machineList: MachineListItem[];
  /**
   * Обработчик открытия изменения
   *
   * @param outlet торговая точка
   */
  onEditClick: (outlet: OutletListItem) => void;
  /**
   * Обработчик открытия удаления
   *
   * @param outlet торговая точка
   */
  onDeleteClick: (outlet: OutletListItem) => void;
};

/**
 * Строка таблицы списка торговых точек
 */
const OutletTableRow: FC<MachineGroupTableRowProps> = ({
  outlet,
  machineList,
  onEditClick,
  onDeleteClick,
}) => {
  const [isOpenMachineList, setIsOpenMachineList] = useState(false);

  // Вспомогательные методы
  const getStatusClassName = (status: StatusColor) => {
    switch (status) {
      case StatusColor.ERROR:
        return styles.alert;
      case StatusColor.WARNING:
        return styles.warning;
      case StatusColor.SUCCESS:
        return styles.success;
    }

    return null;
  };

  // Обработчики
  const handleRowClick = () => {
    setIsOpenMachineList((prevState) => !prevState);
  };

  const handleDeleteClick = () => {
    onDeleteClick(outlet);
  };

  const handleEditClick = () => {
    onEditClick(outlet);
  };

  // render методы
  const renderNameCell = () => (
    <VerticalContainer space="2xs" className={styles.nameCell}>
      <Text size="l" weight="semibold">
        {outlet.name}
      </Text>
      <Text
        size="m"
        view="ghost"
      >{`${outlet.country} ${outlet.city} ${outlet.street} ${outlet.houseNumber}`}</Text>
    </VerticalContainer>
  );

  const renderSalesCell = () => (
    <VerticalContainer className={styles.salesCell} justify="center">
      <Text>{outlet.qtySales}</Text>
    </VerticalContainer>
  );

  const renderActionsCell = () => (
    <HorizontalContainer space="s" className={styles.actions}>
      <Button
        view="ghost"
        size="m"
        iconLeft={IconEditSquare as any}
        onlyIcon
        onClick={handleEditClick}
      />
      <Button
        view="ghost"
        size="m"
        iconLeft={IconTrash as any}
        onlyIcon
        onClick={handleDeleteClick}
      />
    </HorizontalContainer>
  );

  const renderGroupRow = () => (
    <div className={classNames(styles.outlet, getStatusClassName(outlet.colorStatus))}>
      <HorizontalContainer
        className={classNames(
          styles.outletInfo,
          isOpenMachineList && styles.active,
          getStatusClassName(outlet.colorStatus),
        )}
        space={0}
      >
        <div className={styles.leftSide} onClick={handleRowClick}>
          {isOpenMachineList ? (
            <IconArrowUp {...defaultIconProps} size="l" />
          ) : (
            <IconArrowDown {...defaultIconProps} size="l" />
          )}
        </div>
        <div className={styles.groupContent}>
          <HorizontalContainer>
            {renderNameCell()}
            {renderSalesCell()}
          </HorizontalContainer>
          {renderActionsCell()}
        </div>
      </HorizontalContainer>
      {isOpenMachineList && <MachineListInOutlet machineList={machineList} />}
    </div>
  );

  return <>{renderGroupRow()}</>;
};

export default OutletTableRow;
