import { ProductGroup, ProductView } from './storageDTO';
import { enumToArray } from '../enums';

// Базовая информация модели автомата
/**
 * Группы моделей автомата
 */
export enum MachineModelGroup {
  SNACK = 'SNACK',
  COFFEE = 'COFFEE',
  DEFAULT = 'DEFAULT',
}

/**
 * Список групп моделей автоматов
 */
export const machineModelGroupList = enumToArray(MachineModelGroup);

/**
 * Базовая информация модели автомата для списка
 */
export type BaseModelListDTO = {
  /**
   * id модели автомата
   */
  id: number;
  /**
   * Название модели автомата
   */
  name: string;
};

/**
 * Базовая информация создания модели автомата
 */
export type CreateBaseModelDTO = Omit<BaseModelListDTO, 'id'> & {
  /**
   * Количество вариантов объёмов напитка
   */
  qtyDosage: number;
  /**
   * Группа
   */
  groupModel: MachineModelGroup;
};

/**
 * Базовая информация модели автомата
 */
export type BaseModelDTO = BaseModelListDTO & CreateBaseModelDTO;

// Ячейки модели автомата
/**
 * Базовый тип ячейки модели автомата
 */
export type MachineModelBaseCellByList = {
  /**
   * Номер ячейки
   */
  cellNumber: number;
};

// Ячейка продукта
/**
 * Ячейка продукта для списка моделей автоматов
 */
export type MachineModelProductCellByList = MachineModelBaseCellByList & {
  /**
   * Группа продуктов
   */
  group: ProductGroup;
  /**
   * Внешний вид продуктов
   */
  view: ProductView;
};

/**
 * Ячейка продукта для модели автомата для формы создания
 */
export type CreateProductModelCell = MachineModelProductCellByList & {
  /**
   * id категории ячейки
   */
  cellCategoryId: number | null;
  /**
   * id назначения ячейки
   */
  cellPurposeId: number | null;
  /**
   * Максимальный объём ячейки
   */
  maxVolume: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Таймер годности
   */
  expirationTimer: number;
};

/**
 * Ячейка продукта модели автомата
 */
export type ProductModelCell = Omit<CreateProductModelCell, 'cellPurposeId' | 'cellCategoryId'> & {
  /**
   * Название категории ячейки
   */
  cellCategoryName: string;
  /**
   * Название назначения ячейки
   */
  cellPurposeName: string;
};

// Ячейка воды
/**
 * Ячейка воды для списка моделей автоматов
 */
export type MachineModelWaterCellByList = MachineModelBaseCellByList;

/**
 * Ячейка воды модели автомата для формы создания
 */
export type CreateWaterModelCell = MachineModelWaterCellByList & {
  /**
   * Максимальный объём бутылки
   */
  maxVolume?: number;
  /**
   * Минимальный объём бутылки для отправки оповещения
   */
  minVolume?: number;
  /**
   * Подключена бутылка
   */
  isCount: boolean;
  /**
   * Таймер годности
   */
  expirationTimer?: number;
  /**
   * Счётчик годности
   */
  filterValue?: number;
};

/**
 * Ячейка воды модели автомата
 */
export type WaterModelCell = CreateWaterModelCell;

// Ячейка стакана
/**
 * Ячейка стакана для списка моделей автоматов
 */
export type MachineModelCupCellByList = MachineModelBaseCellByList;

/**
 * Ячейка стакана модели автомата для формы создания
 */
export type CreateCupModelCell = MachineModelCupCellByList & {
  /**
   * Объём стакана
   */
  cupVolume: number;
  /**
   * Максимальный объём ячейки с стаканами
   */
  maxVolume?: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume?: number;
  /**
   * Считать количество
   */
  isCount: boolean;
};

/**
 * Ячейка стакана модели автомата
 */
export type CupModelCell = CreateCupModelCell;

// Ячейка расходника
/**
 * Ячейка расходника для списка моделей автоматов
 */
export type MachineModelDisposableCellByList = MachineModelBaseCellByList;

/**
 * Ячейка расходника модели автомата для формы создания
 */
export type CreateDisposableModelCell = MachineModelDisposableCellByList & {
  /**
   * Название расходника
   */
  name: string;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume?: number;
  /**
   * Считать количество
   */
  isCount: boolean;
};

/**
 * Ячейка расходника модели автомата
 */
export type DisposableModelCell = CreateDisposableModelCell;

// Список ячеек модели автомата
/**
 * Ячейки списка моделей автомата
 */
export type MachineModelCellsDTO = {
  /**
   * Ячейки продукта
   */
  cells: MachineModelProductCellByList[];
  /**
   * Ячейки стаканов
   */
  cellCups: MachineModelWaterCellByList[];
  /**
   * Ячейки воды
   */
  cellWaters: MachineModelCupCellByList[];
  /**
   * Ячейки расходников
   */
  cellDisposables: MachineModelDisposableCellByList[];
};

/**
 * Ячейки формы создания модели автоммата
 */
export type CreateCellsModelDTO = {
  /**
   * Ячейки продуктов
   */
  cells: CreateProductModelCell[];
  /**
   * Ячейки стаканов
   */
  cellCups: CreateCupModelCell[];
  /**
   * Ячейки воды
   */
  cellWaters: CreateWaterModelCell[];
  /**
   * Ячейки расходников
   */
  cellDisposables: CreateDisposableModelCell[];
};

/**
 * Ячейки детальной информации модели автомата
 */
export type CellsModelDTO = {
  /**
   * Ячейки продуктов
   */
  cells: ProductModelCell[];
  /**
   * Ячейки стаканов
   */
  cellCups: CupModelCell[];
  /**
   * Ячейки воды
   */
  cellWaters: WaterModelCell[];
  /**
   * Ячейки расходников
   */
  cellDisposables: DisposableModelCell[];
};

// Общие типы для автомата
/**
 * dto модели автомата (для списка моделей автомата)
 */
export type MachineModelDTO = MachineModelCellsDTO & {
  /**
   * id автомата
   */
  id: number;
  /**
   * Название автомата
   */
  name: string;
};

/**
 * dto создания автомата
 */
export type CreateMachineModelDTO = CreateBaseModelDTO & CreateCellsModelDTO;

/**
 * dto детальной информации о автомате
 */
export type MachineModelDetailsDTO = BaseModelDTO & CellsModelDTO;
