import {
  CreateBaseModelDTO,
  CreateCupModelCell,
  CreateDisposableModelCell,
  CreateProductModelCell,
  CreateWaterModelCell,
} from '../../../../types/serverInterface/machineModelDTO';
import { FieldError } from '../../../../types/types';

/**
 * Свойства компонента CreateModelProps
 */
export type CreateModelProps = {
  /**
   * Флаг открытия формы создания модели автомата
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Шаги в форме
 */
export enum CreateModelSteps {
  /**
   * Базовая информация
   */
  BASE = 'BASE',
  /**
   * Ячейки
   */
  CELLS = 'CELLS',
}

/**
 * Ошибки формы базовой информации модели автомата
 */
export type BaseModelError = Record<keyof CreateBaseModelDTO, FieldError>;

/**
 * Ошибки формы ячейки продуктов
 */
export type ProductCellsFieldError = Record<keyof CreateProductModelCell, FieldError>;

/**
 * Ошибки формы ячейки воды
 */
export type WaterCellsFieldError = Record<keyof CreateWaterModelCell, FieldError>;

/**
 * Ошибки формы ячейки стакана
 */
export type CupCellsFieldError = Record<keyof CreateCupModelCell, FieldError>;

/**
 * Ошибки формы ячейки расходников
 */
export type DisposableCellsFieldError = Record<keyof CreateDisposableModelCell, FieldError>;

/**
 * Ошибки формы ячеек модели автомата
 */
export type CellsModelError = {
  /**
   * Ячейки продуктов
   */
  cell: Record<number, ProductCellsFieldError>;
  /**
   * Ячейки воды
   */
  waterCell: Record<number, WaterCellsFieldError>;
  /**
   * Ячейки стаканов
   */
  cupCell: Record<number, CupCellsFieldError>;
  /**
   * Ячейки расходников
   */
  disposableCell: Record<number, DisposableCellsFieldError>;
};
