import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import MachineList from './MachineList';
import Machine from './Machine';
import ModelList from './ModelList';
import PrivateRolesPage from '../RolesPage/PrivateRolesPage';
import { Roles } from '../../types/serverInterface/cabinetDTO';

/**
 * Роут модуля "Управление автоматом"
 */
const MachineControlPage: FC = () => (
  <Routes>
    <Route element={<PrivateRolesPage needRoles={[Roles.DEVELOPER]} />}>
      <Route path="/model/*" element={<ModelList />} />
    </Route>
    <Route
      element={
        <PrivateRolesPage
          needRoles={[Roles.ORGANIZATION_MEMBER, Roles.ORGANIZATION_OWNER, Roles.MANUFACTURER]}
          needOrganization
        />
      }
    >
      <Route path="/machine/:machineId" element={<Machine />} />
    </Route>
    <Route
      element={
        <PrivateRolesPage
          needRoles={[Roles.ORGANIZATION_MEMBER, Roles.ORGANIZATION_OWNER, Roles.MANUFACTURER]}
          needOrganization
        />
      }
    >
      <Route path="/outlet/machine/:machineId" element={<Machine />} />
    </Route>
    <Route
      element={
        <PrivateRolesPage
          needRoles={[Roles.ORGANIZATION_MEMBER, Roles.ORGANIZATION_OWNER, Roles.MANUFACTURER]}
          needOrganization
        />
      }
    >
      <Route path="/machine/*" element={<MachineList />} />
    </Route>
  </Routes>
);

export default MachineControlPage;
