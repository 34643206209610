import { FC, useMemo } from 'react';
import { ProductByInfo } from '../../../../types/serverInterface/promoCodeDTO';
import styles from './PromoCodeProducts.module.scss';
import VerticalContainer from '../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { Badge } from '@consta/uikit/Badge';
import classNames from 'classnames';

type PromoCodeProductsProps = {
  product: ProductByInfo;
};

type TransformBrandListItem = {
  brandName: string;
  lineName: string;
  ingredientName: string;
  id: string;
};

function transformBrandList(
  originalList: {
    id: number;
    name: string;
    ingredientLines: {
      id: number;
      name: string;
      ingredients: { id: number; name: string }[];
    }[];
  }[],
): TransformBrandListItem[] {
  const transformedList: TransformBrandListItem[] = [];

  originalList?.forEach((brand) => {
    if (brand.ingredientLines?.length === 0) {
      transformedList.push({
        brandName: brand.name,
        lineName: 'Все',
        ingredientName: 'Все',
        id: `brand${brand.id}`,
      });
    } else {
      brand.ingredientLines?.forEach((line) => {
        if (line.ingredients?.length === 0) {
          (transformedList || []).push({
            brandName: brand.name,
            lineName: line.name,
            ingredientName: 'Все',
            id: `line${line.id}`,
          });
        } else {
          line.ingredients?.forEach((ingredient) => {
            (transformedList || []).push({
              brandName: brand.name,
              lineName: line.name,
              ingredientName: ingredient.name,
              id: `ingredient${ingredient.id}`,
            });
          });
        }
      });
    }
  });

  return transformedList;
}

const PromoCodeProducts: FC<PromoCodeProductsProps> = ({ product }) => {
  const { brands, views, categories } = product;

  const transformedBrandList = useMemo(() => transformBrandList(brands), [brands]);

  // render методы
  const renderFilters = () => (
    <HorizontalContainer space="s">
      <Badge
        // status={categoryIds?.length ? 'normal' : 'system'}
        label={`Категория: ${categories?.length ? categories.map(({ name }) => name).join(', ') : 'все категории'}`}
      />
      <Badge
        // status={viewIds?.length ? 'normal' : 'system'}
        label={`Вид: ${views?.length ? views.map(({ name }) => name).join(', ') : 'все виды'}`}
      />
    </HorizontalContainer>
  );

  const renderTableHeader = () => (
    <thead className={styles.header}>
      <tr className={styles.columns}>
        <th className={styles.cell}>
          <Text className={styles.text} weight="semibold" size="m">
            Бренд
          </Text>
        </th>
        <th className={styles.cell}>
          <Text className={styles.text} weight="semibold" size="m">
            Линейка
          </Text>
        </th>
        <th className={styles.cell}>
          <Text className={styles.text} weight="semibold" size="m">
            Продукт
          </Text>
        </th>
      </tr>
    </thead>
  );

  const renderTableRow = (
    { id, lineName, ingredientName, brandName }: TransformBrandListItem,
    index: number,
  ) => (
    <tr className={classNames(styles.row, index % 2 === 1 && styles.zebra)} key={id}>
      <th className={styles.cell}>
        <Text className={styles.text} weight="regular" size="l">
          {brandName}
        </Text>
      </th>
      <th className={styles.cell}>
        <Text className={styles.text} weight="regular" size="l">
          {lineName}
        </Text>
      </th>
      <th className={styles.cell}>
        <Text className={styles.text} weight="regular" size="l">
          {ingredientName}
        </Text>
      </th>
    </tr>
  );

  const renderTableContent = () => (
    <tbody className={styles.body}>{transformedBrandList?.map(renderTableRow)}</tbody>
  );

  const renderTable = () => (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        {renderTableHeader()}
        {renderTableContent()}
      </table>
    </div>
  );

  return (
    <VerticalContainer className={styles.card} space="2xl">
      <Text className={styles.text} size="2xl" weight="semibold">
        Доступные продукты
      </Text>
      <VerticalContainer space="l">
        {renderFilters()}
        {transformedBrandList?.length ? renderTable() : <Text>Все продукты</Text>}
      </VerticalContainer>
    </VerticalContainer>
  );
};

export default PromoCodeProducts;
