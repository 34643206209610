import { FC, useMemo } from 'react';
import {
  MachineModelCellsDTO,
  MachineModelProductCellByList,
} from '../../../../types/serverInterface/machineModelDTO';
import { ProductGroup } from '../../../../types/serverInterface/storageDTO';
import { getSortedProductGroup } from '../../../../components/Machine/MachineStorage/helpers';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import CellsSchemeItem from './CellsSchemeItem';
import { MachineCellGroup } from '../../../../types/serverInterface/machineDTO';

/**
 * Свойства комопнента CellsScheme
 */
type CellsSchemeProps = {
  /**
   * Ячейки модели автомата
   */
  modelCells: MachineModelCellsDTO;
};

/**
 * Служебное, требуется для валидной группировки ячеек продуктов
 */
const initialStorage: {
  [key in ProductGroup]: MachineModelProductCellByList[];
} = {
  [ProductGroup.CONCENTRATE]: [],
  [ProductGroup.POWDER]: [],
  [ProductGroup.COFFEE]: [],
};

/**
 * Схема ячеек модели автомата
 */
const CellsScheme: FC<CellsSchemeProps> = ({ modelCells }) => {
  const { cells, cellWaters, cellCups, cellDisposables } = modelCells;

  const sortedCells = useMemo(() => {
    const separateStorageInfoByGroup = (storageInfoArray: MachineModelProductCellByList[]) => {
      const separatedStorageInfo: {
        [key in ProductGroup]: MachineModelProductCellByList[];
      } = JSON.parse(JSON.stringify(initialStorage));

      storageInfoArray.forEach((storageInfo) => {
        separatedStorageInfo[storageInfo.group].push(storageInfo);
      });

      return separatedStorageInfo;
    };

    return separateStorageInfoByGroup(cells);
  }, [cells]);

  const sortedGroup = useMemo(() => getSortedProductGroup(sortedCells), [sortedCells]);

  return (
    <HorizontalContainer space="s">
      {sortedGroup.map((key) => (
        <HorizontalContainer space="2xs" key={key}>
          {sortedCells[key].map((cell, index) => (
            <CellsSchemeItem key={index} cell={cell} />
          ))}
        </HorizontalContainer>
      ))}
      <HorizontalContainer space="2xs">
        <CellsSchemeItem cell={{ ...cellWaters[0], group: MachineCellGroup.WATER }} />
      </HorizontalContainer>
      <HorizontalContainer space="2xs">
        {cellCups.map((cell, index) => (
          <CellsSchemeItem key={index} cell={{ ...cell, group: MachineCellGroup.CUP }} />
        ))}
      </HorizontalContainer>
      <HorizontalContainer space="2xs">
        {cellDisposables.map((cell, index) => (
          <CellsSchemeItem key={index} cell={{ ...cell, group: MachineCellGroup.DISPOSABLE }} />
        ))}
      </HorizontalContainer>
    </HorizontalContainer>
  );
};

export default CellsScheme;
