import React, { FC } from 'react';
import ContentCard from '../../../../components/ContentCard';
import { Text } from '@consta/uikit/Text';
import VerticalContainer from '../../../../components/VerticalContainer';
import { OrganizationContactInfoProps } from './types';
import styles from './OrganizationContactInfo.module.scss';
import { IconSearch } from '../../../../assets/icon/IconSearch';
import { defaultIconProps } from '../../../../consts/defaultIconProps';
import { useTranslation } from 'react-i18next';

/**
 * Форма со списком контактов организации
 */
const OrganizationContactInfo: FC<OrganizationContactInfoProps> = ({ organizationContacts }) => {
  const { t } = useTranslation();

  const noContacts = !organizationContacts || organizationContacts.length === 0;

  //render методы
  const renderContent = () => (
    <ContentCard className={styles.contentCard}>
      <VerticalContainer>
        {organizationContacts?.map((contact, index) => (
          <VerticalContainer space={'3xs'} key={index}>
            <Text size="s" view="secondary">
              {contact.name}
            </Text>
            <Text size="l">{contact.contact}</Text>
          </VerticalContainer>
        ))}
      </VerticalContainer>
    </ContentCard>
  );

  const renderEmptyContent = () => (
    <ContentCard className={styles.emptyContentCard}>
      <VerticalContainer space={'s'}>
        <div className={styles.iconContainer}>
          <div className={styles.ellipse}></div>
          <IconSearch size="m" {...defaultIconProps} className={styles.iconSearch} />
        </div>
        <Text view="primary" size="xl" weight="semibold">
          {t('organization.contacts.info.notFound.label')}
        </Text>
      </VerticalContainer>
    </ContentCard>
  );

  return <>{noContacts ? renderEmptyContent() : renderContent()}</>;
};

export default OrganizationContactInfo;
