import { FC, useEffect, useState } from 'react';
import DefaultModal from '../../../../components/DefaultModal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../app/hooks/store';
import {
  CreateBaseModelDTO,
  CreateCellsModelDTO,
  CreateCupModelCell,
  CreateDisposableModelCell,
  CreateProductModelCell,
  CreateWaterModelCell,
} from '../../../../types/serverInterface/machineModelDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import CreateModelBasicForm from './CreateModelBasicForm';
import CreateModelCellsForm from './CreateModelCellsForm';
import {
  getCellCategoryListAction,
  getCellPurposeListAction,
} from '../../../../state/productBase/actions';
import { BaseModelError, CellsModelError, CreateModelProps, CreateModelSteps } from './types';

import { validateBasic, validateCells, validateCellsNumbers } from './helpers';
import {
  formCellsInitialError,
  createBasicModelInitialValue,
  createCellsModelInitialValue,
  initialCup,
  initialDisposable,
  initialProduct,
  formBasicInitialError,
} from './consts';
import { createMachineModelAction } from '../../../../state/machineControl/actions';
import { initialFieldError } from '../../../../helpers/validateHelpers';

/**
 * Форма создания модели автомата
 */
const CreateModel: FC<CreateModelProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [formStep, setFormStep] = useState<CreateModelSteps>(CreateModelSteps.BASE);
  const [basicFormState, setBasicFormState] = useState<CreateBaseModelDTO>(
    createBasicModelInitialValue,
  );
  const [cellsFormState, setCellsFormState] = useState<CreateCellsModelDTO>(
    createCellsModelInitialValue,
  );
  const [basicFormError, setBasicFormError] = useState<BaseModelError>(formBasicInitialError);
  const [cellsFormError, setCellsFormError] = useState<CellsModelError>(formCellsInitialError);

  useEffect(() => {
    dispatch(getCellCategoryListAction());
    dispatch(getCellPurposeListAction());
  }, [dispatch]);

  // Вспомогательные методы
  const getPrimaryButtonLabel = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return t('machineControl.model.create.actions.next.button.label');
      case CreateModelSteps.CELLS:
        return t('machineControl.model.create.actions.save.button.label');
    }
  };

  const getCancelButtonLabel = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return t('machineControl.model.create.actions.cancel.button.label');
      case CreateModelSteps.CELLS:
        return t('machineControl.model.create.actions.back.button.label');
    }
  };

  const recalculationCellNumbers = () => {
    setCellsFormState((prevState) => {
      const productCellNumberStart = 1;
      const waterCellNumberStart = productCellNumberStart + prevState.cells.length;
      const cupCellNumberStart = waterCellNumberStart + prevState.cellWaters.length;
      const disposableCellNumberStart = cupCellNumberStart + prevState.cellWaters.length;

      const newState = {
        ...prevState,
        cells: prevState.cells.map((item, index) => ({
          ...item,
          cellNumber: productCellNumberStart + index,
        })),
        cellWaters: prevState.cellWaters.map((item, index) => ({
          ...item,
          cellNumber: waterCellNumberStart + index,
        })),
        cellCups: prevState.cellCups.map((item, index) => ({
          ...item,
          cellNumber: cupCellNumberStart + index,
        })),
        cellDisposables: prevState.cellDisposables.map((item, index) => ({
          ...item,
          cellNumber: disposableCellNumberStart + index,
        })),
      };

      setCellsFormError((prevState) => validateCellsNumbers(newState, prevState));

      return newState;
    });
  };

  // Обработчики
  const handleCancelButtonClick = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return handleClose();
      case CreateModelSteps.CELLS:
        return setFormStep(CreateModelSteps.BASE);
    }
  };

  const handlePrimaryButtonClick = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return handleCellsStepClick();
      case CreateModelSteps.CELLS:
        return handleSubmit();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleBasicChange = (key: keyof CreateBaseModelDTO) => (value: string | null) => {
    setBasicFormError((prevState) => ({ ...prevState, [key]: initialFieldError }));

    setBasicFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleCellsStepClick = () => {
    validateBasic(basicFormState, setBasicFormError) && setFormStep(CreateModelSteps.CELLS);
  };

  const handleSubmit = () => {
    validateCells(cellsFormState, setCellsFormError) &&
      dispatch(
        createMachineModelAction({
          ...basicFormState,
          ...cellsFormState,
          cellWaters: cellsFormState.cellWaters?.map((cell) => ({
            ...cell,
            filterValue: cell?.filterValue && cell?.filterValue * 1000,
            maxVolume: cell?.maxVolume && cell?.maxVolume * 1000,
            minVolume: cell?.minVolume && cell?.minVolume * 1000,
          })),
        }),
      ).then(handleClose);
  };

  const handleProductChange =
    (index: number) => (key: keyof CreateProductModelCell) => (value: string | number | null) => {
      setCellsFormError((prevState) => ({
        ...prevState,
        cell: {
          ...prevState.cell,
          [index]: { ...prevState.cell?.[index], [key]: initialFieldError },
        },
      }));

      setCellsFormState((prevState) => {
        const updatedCells = [...prevState.cells];
        updatedCells[index] = { ...updatedCells[index], [key]: value };
        return { ...prevState, cells: updatedCells };
      });
    };

  const handleAddProductClick = () => {
    setCellsFormState((prevState) => {
      const lastCellNumber =
        prevState.cells.length > 0 ? prevState.cells[prevState.cells.length - 1].cellNumber : 0;
      return {
        ...prevState,
        cells: [...prevState.cells, { ...initialProduct, cellNumber: lastCellNumber + 1 }],
      };
    });
  };

  const handleDeleteProductClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const newState = {
        ...prevState,
        cells: [...prevState.cells.slice(0, index), ...prevState.cells.slice(index + 1)],
      };

      handleValidateCells(newState);

      return newState;
    });
  };

  const handleCopyProductClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const copyProduct = prevState.cells?.[index] || initialProduct;
      const cellsListLength = prevState.cells.length;
      const lastCellNumber =
        cellsListLength > 0 ? prevState.cells[cellsListLength - 1].cellNumber : 0;

      setCellsFormError((prevState) => ({
        ...prevState,
        cell: {
          ...prevState.cell,
          [cellsListLength]: { ...prevState.cell?.[index] },
        },
      }));

      return {
        ...prevState,
        cells: [...prevState.cells, { ...copyProduct, cellNumber: lastCellNumber + 1 }],
      };
    });
  };

  const handleWaterChange =
    (index: number) => (key: keyof CreateWaterModelCell) => (value: string | null) => {
      setCellsFormError((prevState) => ({
        ...prevState,
        waterCell: {
          ...prevState.waterCell,
          [index]: { ...prevState.waterCell?.[index], [key]: initialFieldError },
        },
      }));

      setCellsFormState((prevState) => {
        const updatedCells = [...prevState.cellWaters];
        updatedCells[index] = { ...updatedCells[index], [key]: value };
        return { ...prevState, cellWaters: updatedCells };
      });
    };

  const handleCupChange =
    (index: number) => (key: keyof CreateCupModelCell) => (value: string | null) => {
      setCellsFormError((prevState) => ({
        ...prevState,
        cupCell: {
          ...prevState.cupCell,
          [index]: { ...prevState.cupCell?.[index], [key]: initialFieldError },
        },
      }));

      setCellsFormState((prevState) => {
        const updatedCells = [...prevState.cellCups];
        updatedCells[index] = { ...updatedCells[index], [key]: value };
        return { ...prevState, cellCups: updatedCells };
      });
    };

  const handleAddCupClick = () => {
    setCellsFormState((prevState) => {
      const lastCellNumber =
        prevState.cellCups.length > 0
          ? prevState.cellCups[prevState.cellCups.length - 1].cellNumber
          : 0;
      return {
        ...prevState,
        cellCups: [...prevState.cellCups, { ...initialCup, cellNumber: lastCellNumber + 1 }],
      };
    });
  };

  const handleDeleteCupClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const newState = {
        ...prevState,
        cellCups: [...prevState.cellCups.slice(0, index), ...prevState.cellCups.slice(index + 1)],
      };

      handleValidateCells(newState);

      return newState;
    });
  };

  const handleCopyCupClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const copyCup = prevState.cellCups?.[index] || initialCup;
      const cellsListLength = prevState.cellCups.length;
      const lastCellNumber =
        cellsListLength > 0 ? prevState.cellCups[cellsListLength - 1].cellNumber : 0;

      setCellsFormError((prevState) => ({
        ...prevState,
        cupCell: {
          ...prevState.cupCell,
          [cellsListLength]: { ...prevState.cupCell?.[index] },
        },
      }));

      return {
        ...prevState,
        cellCups: [...prevState.cellCups, { ...copyCup, cellNumber: lastCellNumber + 1 }],
      };
    });
  };

  const handleDisposableChange =
    (index: number) => (key: keyof CreateDisposableModelCell) => (value: string | null) => {
      setCellsFormError((prevState) => ({
        ...prevState,
        disposableCell: {
          ...prevState.disposableCell,
          [index]: { ...prevState.disposableCell?.[index], [key]: initialFieldError },
        },
      }));

      setCellsFormState((prevState) => {
        const updatedCells = [...prevState.cellDisposables];
        updatedCells[index] = { ...updatedCells[index], [key]: value };
        return { ...prevState, cellDisposables: updatedCells };
      });
    };

  const handleAddDisposableClick = () => {
    setCellsFormState((prevState) => {
      const lastCellNumber =
        prevState.cellDisposables.length > 0
          ? prevState.cellDisposables[prevState.cellDisposables.length - 1].cellNumber
          : 0;
      return {
        ...prevState,
        cellDisposables: [
          ...prevState.cellDisposables,
          { ...initialDisposable, cellNumber: lastCellNumber + 1 },
        ],
      };
    });
  };

  const handleDeleteDisposableClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const newState = {
        ...prevState,
        cellDisposables: [
          ...prevState.cellDisposables.slice(0, index),
          ...prevState.cellDisposables.slice(index + 1),
        ],
      };

      handleValidateCells(newState);

      return newState;
    });
  };

  const handleCopyDisposableClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const copyDisposable = prevState.cellDisposables?.[index] || initialDisposable;
      const cellsListLength = prevState.cellDisposables.length;
      const lastCellNumber =
        cellsListLength > 0 ? prevState.cellDisposables[cellsListLength - 1].cellNumber : 0;

      setCellsFormError((prevState) => ({
        ...prevState,
        disposableCell: {
          ...prevState.disposableCell,
          [cellsListLength]: { ...prevState.disposableCell?.[index] },
        },
      }));

      return {
        ...prevState,
        cellDisposables: [
          ...prevState.cellDisposables,
          { ...copyDisposable, cellNumber: lastCellNumber + 1 },
        ],
      };
    });
  };

  const handleValidateBasic = () => {
    validateBasic(basicFormState, setBasicFormError);
  };

  const handleValidateCells = (cells?: CreateCellsModelDTO) => {
    validateCells(cells || cellsFormState, setCellsFormError);
  };

  const handleValidate = () => {
    if (formStep === CreateModelSteps.CELLS) {
      handleValidateCells();
    } else {
      handleValidateBasic();
    }
  };

  // render методы
  const renderActions = () => (
    <>
      <Button label={getCancelButtonLabel()} view="clear" onClick={handleCancelButtonClick} />
      {formStep === CreateModelSteps.CELLS && (
        <Button
          label={t('machineControl.model.create.actions.recalculate.button.label')}
          view="clear"
          onClick={recalculationCellNumbers}
        />
      )}
      <Button
        label={t('machineControl.model.create.actions.validate.button.label')}
        view="clear"
        onClick={handleValidate}
      />
      <Button label={getPrimaryButtonLabel()} onClick={handlePrimaryButtonClick} />
    </>
  );

  const renderContent = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return (
          <CreateModelBasicForm
            value={basicFormState}
            error={basicFormError}
            onChange={handleBasicChange}
          />
        );
      case CreateModelSteps.CELLS:
        return (
          <CreateModelCellsForm
            value={cellsFormState}
            errors={cellsFormError}
            onProductChange={handleProductChange}
            onAddProductClick={handleAddProductClick}
            onDeleteProductClick={handleDeleteProductClick}
            onCopyProductClick={handleCopyProductClick}
            onWaterChange={handleWaterChange}
            onCupChange={handleCupChange}
            onAddCupClick={handleAddCupClick}
            onDeleteCupClick={handleDeleteCupClick}
            onCopyCupClick={handleCopyCupClick}
            onDisposableChange={handleDisposableChange}
            onAddDisposableClick={handleAddDisposableClick}
            onDeleteDisposableClick={handleDeleteDisposableClick}
            onCopyDisposableClick={handleCopyDisposableClick}
          />
        );
    }
  };

  return (
    <DefaultModal
      modalTitle={t('machineControl.model.create.modal.title')}
      isOpen={isOpen}
      renderActions={renderActions}
      onClose={handleClose}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default CreateModel;
