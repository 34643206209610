import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectOutletFilters, selectOutletList } from '../../../state/machineControl/selectors';
import { useEffect, useMemo, useState } from 'react';
import {
  getOutletListAction,
  getOutletListFiltersAction,
} from '../../../state/machineControl/actions';
import {
  machineListInitialFilters,
  useMachineList,
} from '../../MachineControlPage/MachineList/hook';
import {
  MachineFiltersDTO,
  MachineListFiltersModelInfo,
  MachineListItem,
} from '../../../types/serverInterface/machineListDTO';
import { StatusColor } from '../../../types/serverInterface/machineDTO';
import { OutletListItem } from '../../../types/serverInterface/outletDTO';

const outletStatusesCountInitialValue = {
  error: 0,
  success: 0,
  warning: 0,
};

/**
 * Хук для работы со списком торговых точек
 *
 * @param organizationId id организации
 */
export const useOutletList = (organizationId: number | null) => {
  const dispatch = useAppDispatch();

  const { state: outletListState } = useAppSelector(selectOutletList());
  const { state: filtersState } = useAppSelector(selectOutletFilters());

  const {
    machineList,
    filters: machineFilters,
    setFilters: setMachineFilters,
  } = useMachineList(organizationId);

  const [filters, setFilters] = useState<MachineFiltersDTO>({
    ...machineListInitialFilters,
    organizationId,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [outletStatusesCount, setOutletStatusesCount] = useState(outletStatusesCountInitialValue);

  const salesFilter = filters.salesFilter;

  const machineOutletMap = useMemo(() => {
    const map: Record<number, MachineListItem[]> = {};

    machineList?.forEach((machine) => {
      if (machine.outletId) {
        map[machine.outletId] = map[machine.outletId] || [];
        map[machine.outletId].push(machine);
      }
    });

    return map;
  }, [machineList]);

  const formattedOutletList: OutletListItem[] = useMemo(() => {
    setOutletStatusesCount(outletStatusesCountInitialValue);

    return (outletListState || { outlets: [] }).outlets.map((outlet) => {
      const formattedOutlet = {
        ...outlet,
        qtySales: machineOutletMap[outlet.id]?.reduce((acc, { salePeriodResult }) => {
          return acc + salePeriodResult.qty;
        }, 0),
        colorStatus: machineOutletMap[outlet.id]?.reduce((acc: StatusColor, { status }) => {
          if (acc === StatusColor.NULL) return status.color;
          if (acc === StatusColor.SUCCESS) return status.color;
          if (acc === StatusColor.WARNING && status.color !== StatusColor.SUCCESS)
            return status.color;
          if (
            acc === StatusColor.ERROR &&
            status.color !== StatusColor.SUCCESS &&
            status.color !== StatusColor.WARNING
          )
            return status.color;
          return acc;
        }, StatusColor.NULL),
      };

      if (formattedOutlet.colorStatus === StatusColor.SUCCESS) {
        setOutletStatusesCount((prevState) => ({
          ...prevState,
          success: prevState.success + 1,
        }));
      }
      if (formattedOutlet.colorStatus === StatusColor.WARNING) {
        setOutletStatusesCount((prevState) => ({
          ...prevState,
          warning: prevState.warning + 1,
        }));
      }
      if (formattedOutlet.colorStatus === StatusColor.ERROR) {
        setOutletStatusesCount((prevState) => ({
          ...prevState,
          error: prevState.error + 1,
        }));
      }

      return formattedOutlet;
    });
  }, [outletListState, machineOutletMap]);

  const filterOutletList = useMemo(
    () =>
      (formattedOutletList &&
        formattedOutletList?.filter(
          ({ name, id }) =>
            name &&
            name.toLowerCase().includes(searchQuery.toLowerCase()) &&
            (machineOutletMap[id]?.find(({ status }) =>
              filters.statusColors?.includes(status.color),
            ) ||
              !filters.statusColors) &&
            (machineOutletMap[id]?.find(({ model }) =>
              filters.machineModelIds?.includes(model.id || 0),
            ) ||
              !filters.machineModelIds ||
              !filters.machineModelIds.length),
        )) ||
      [],
    [searchQuery, formattedOutletList, machineOutletMap, filters],
  );

  useEffect(() => {
    if (organizationId) {
      dispatch(getOutletListAction(organizationId, { salesFilter }));
    }

    // Это поведение верное. Таймер нужен для регулярного обновления списка автоматов
    // Это требование бизнеса. В дальнейшем переделается на push уведомления или на websocket
    const interval = setInterval(() => {
      if (organizationId) {
        dispatch(getOutletListAction(organizationId, { salesFilter }));
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, organizationId, salesFilter]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOutletListFiltersAction(organizationId));
    }

    // Это поведение верное. Таймер нужен для регулярного обновления списка автоматов
    // Это требование бизнеса. В дальнейшем переделается на push уведомления или на websocket
    const interval = setInterval(() => {
      if (organizationId) {
        dispatch(getOutletListFiltersAction(organizationId));
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, organizationId]);

  return {
    outletList: outletListState && outletListState.outlets,
    filterOutletList,
    machineOutletMap,
    outletStatusesCount,
    machineAtModelsCount: filtersState?.models.map(
      ({ id, qty, name }): MachineListFiltersModelInfo => ({
        modelId: id,
        modelName: name,
        modelCount: qty,
      }),
    ),
    filters: {
      ...filters,
      salesFilter: machineFilters.salesFilter,
    },
    machineFilters,
    searchQuery,
    setFilters,
    setMachineFilters,
    setSearchQuery,
  };
};
