import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../app/api';
import {
  CreatePromoCodeGroupReq,
  CreatePromoCodeGroupRes,
  CreatePromoCodeReq,
  CreatePromoCodeRes,
  EditPromoCodeReq,
  GeneratePromoCodeRes,
  PromoCodeGroupRes,
  PromoCodeListFiltersReq,
  PromoCodeListFiltersRes,
  PromoCodeListRes,
  PromoCodeRes,
} from '../../types/serverInterface/promoCodeDTO';
import { Pagination } from '../../types/types';

/**
 * Создание промокода
 *
 * @param data данные формы создания промокода
 */
export const createPromoCodeGroupThunk = createAsyncThunk<
  CreatePromoCodeGroupRes,
  CreatePromoCodeGroupReq
>('createPromoCodeGroup', async (data) => {
  return await api.promoCode.createPromoCodeGroup(data);
});

/**
 * Получение списка групп промокодов организации
 *
 * @param organizationId id организации
 */
export const getPromoCodeGroupListThunk = createAsyncThunk<PromoCodeGroupRes[], number>(
  'getPromoCodeGroupList',
  async (organizationId) => {
    return await api.promoCode.getPromoCodeGroupList(organizationId);
  },
);

/**
 * Генерация промокода
 *
 * @param organizationId id организации
 */
export const generatePromoCodeThunk = createAsyncThunk<GeneratePromoCodeRes, number>(
  'generatePromoCode',
  async (organizationId) => {
    return await api.promoCode.generatePromoCode(organizationId);
  },
);

/**
 * Создания промокода
 *
 * @param data данные формы создания промокода
 */
export const createPromoCodeThunk = createAsyncThunk<CreatePromoCodeRes, CreatePromoCodeReq>(
  'createPromoCode',
  async (data) => {
    return api.promoCode.createPromoCode(data);
  },
);

/**
 * Изменение промокода
 *
 * @param data данные формы изменения промокода
 * @param promoCodeId  id промокода
 */
export const editPromoCodeThunk = createAsyncThunk<
  any,
  { data: EditPromoCodeReq; promoCodeId: number }
>('editPromoCode', async ({ data, promoCodeId }) => {
  return await api.promoCode.editPromoCode(data, promoCodeId);
});

/**
 * Получение информации о промокоде
 *
 * @param promoCodeId id промокода
 */
export const getPromoCodeThunk = createAsyncThunk<PromoCodeRes, number>(
  'getPromoCode',
  async (promoCodeId) => {
    return await api.promoCode.getPromoCode(promoCodeId);
  },
);

/**
 * Получение списка промокодов
 *
 * @param organizationId id организации
 * @param filters фильтры
 * @param pagination страницы
 */
export const getPromoCodeListThunk = createAsyncThunk<
  PromoCodeListRes,
  { organizationId: number; filters: PromoCodeListFiltersReq; pagination: Pagination }
>('getPromoCodeList', async ({ organizationId, pagination, filters }) => {
  return await api.promoCode.getPromoCodeList(organizationId, filters, pagination);
});

/**
 * Получение списка промокодов для экспорта
 *
 * @param organizationId id организации
 * @param filters фильтры
 * @param pagination страницы
 */
export const getPromoCodeListByExportThunk = createAsyncThunk<
  PromoCodeListRes,
  { organizationId: number; filters: PromoCodeListFiltersReq; pagination: Pagination }
>('getPromoCodeList', async ({ organizationId, pagination, filters }) => {
  return await api.promoCode.getPromoCodeList(organizationId, filters, pagination);
});

/**
 * Переключение состояние избранности промокода
 *
 * @param promoCodeId id промокода
 * @param isSelected флаг выбранного промокода
 */
export const switchSelectPromoCodeThunk = createAsyncThunk<
  void,
  { promoCodeId: number; isSelected: boolean }
>('switchSelectPromoCode', async ({ promoCodeId, isSelected }) => {
  return await api.promoCode.switchSelectPromoCode(promoCodeId, isSelected);
});

/**
 * Получение пограничных значений для фильтров списка промокодов
 *
 * @param organizationId id организации
 * @param filters Текущие фильтры
 */
export const getFilterPromoCodeThunk = createAsyncThunk<
  PromoCodeListFiltersRes,
  { organizationId: number; filters: PromoCodeListFiltersReq }
>('getFilterPromoCode', async ({ organizationId, filters }) => {
  return await api.promoCode.getFilterPromoCode(organizationId, filters);
});
