import { FC, ReactNode } from 'react';
import styles from './DefaultInfo.module.scss';

/**
 * Свойства компонента DefaultInfo
 */
type DefaultInfoProps = {
  children?: ReactNode;
};

/**
 * Универсальный компонент для использования на модалках информации
 *
 * @param children контент внутри информации
 */
const DefaultInfo: FC<DefaultInfoProps> = ({ children }) => {
  return <div className={styles.defaultInfo}>{children}</div>;
};

export default DefaultInfo;
