import React, { useEffect, useState } from 'react';
import AuthPage from './AuthPage';
import { Theme, ThemePreset } from '@consta/uikit/__internal__/src/components/Theme';
import { presetGpnDark, presetGpnDefault } from '../theme';
import styles from './App.module.scss';
import { useAppDispatch, useAppSelector } from '../app/hooks/store';
import { checkToken } from '../state/auth';
import PrivateRouteGuard from './AuthPage/AuthRoute/PrivateRouteGuard';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import CabinetPage from './CabinetPage';
import ProductBasePage from './ProductBasePage';
import ProductBaseConfigPage from './ProductBaseConfigPage';
import MachineControlPage from './MachineControlPage';
import NotificationsList from '../components/Notification/NotificationsList';
import PromoCodePage from './PromoCodePage';
import TopMenu from '../components/TopMenu';
import PrivateRolesPage from './RolesPage/PrivateRolesPage';
import { Roles } from '../types/serverInterface/cabinetDTO';
import ErrorPage from './ErrorPage';
import { selectCabinet } from '../state/user/selectors';
import OrganizationPage from './OrganizationPage';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import LeftMenu from '../components/LeftMenu';
import OutletPage from './OutletPage';

export enum ThemeName {
  gpnDefault = 'gpnDefault',
  gpnDark = 'gpnDark',
}

export enum DisplayType {
  DESKTOP = 'DESKTOP',
  LAPTOP = 'LAPTOP',
}

const innerWidth = window.innerWidth;

// export const DisplayTypeContext = React.createContext(DisplayType.DESKTOP);
export const displayTypeValue = innerWidth > 1800 ? DisplayType.DESKTOP : DisplayType.LAPTOP;

export const isLaptop = innerWidth < 1800;

function getPreset(themeName: ThemeName): ThemePreset {
  const obj = {
    gpnDefault: presetGpnDefault,
    gpnDark: presetGpnDark,
  };
  return obj[themeName] || presetGpnDefault;
}

const App: React.FC = () => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const [theme, setTheme] = useState<ThemeName>(ThemeName.gpnDefault);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const cabinet = useAppSelector(selectCabinet());

  useEffect(() => {
    const { role, organizationIds } = cabinet || { role: null, organizationIds: [0] };
    const organizationId = organizationIds[0];

    if (role === Roles.ORGANIZATION_OWNER || role === Roles.ORGANIZATION_MEMBER) {
      localStorage.setItem('organizationId', String(organizationId));

      if (location.pathname === '/') {
        navigate(`/organization/members/list`);
      }
    }
    // Не исправлять зависимости useEffect. Это будет постоянно перенаправлять пользователя на страницу списка
    // пользователей
  }, [cabinet]);

  useEffect(() => {
    const theme = localStorage.getItem('theme');

    theme && setTheme(theme as ThemeName);
  }, []);

  useEffect(() => {
    dispatch(checkToken());
    console.log('version: 9657 Доработка бота логирования ошибок на фронте');
  }, [dispatch]);

  // Обработчики
  const handleThemeChange = ({ value }: { value: ThemeName }) => {
    setTheme(value);
    localStorage.setItem('theme', value);
  };

  // TODO: тут локализация
  // Рендер методы
  const renderNeedAuthRoute = () => (
    <div id="app" className={classNames(styles.app, isLaptop && styles.isLaptop)}>
      <LeftMenu />
      {/*<LeftMenuDeprecated />*/}
      <NotificationsList />
      <div id="appContent" className={styles.appContent}>
        <TopMenu theme={theme} onThemeChange={handleThemeChange} />
        {/*{renderContextMenu()}*/}
        <Routes>
          <Route
            element={
              <PrivateRolesPage
                needRoles={[
                  Roles.MANUFACTURER,
                  Roles.ORGANIZATION_MEMBER,
                  Roles.ORGANIZATION_OWNER,
                ]}
              />
            }
          >
            <Route path="/organization/*" element={<OrganizationPage />} />
          </Route>
          <Route
            element={
              <PrivateRolesPage
                needRoles={[
                  Roles.DEVELOPER,
                  Roles.MANUFACTURER,
                  Roles.ORGANIZATION_MEMBER,
                  Roles.ORGANIZATION_OWNER,
                ]}
              />
            }
          >
            <Route path="/productBase/*" element={<ProductBasePage />} />
          </Route>
          <Route element={<PrivateRolesPage needRoles={[Roles.DEVELOPER]} />}>
            <Route path="/productBaseConfig/*" element={<ProductBaseConfigPage />} />
          </Route>
          <Route
            element={
              <PrivateRolesPage
                needRoles={[
                  Roles.ORGANIZATION_MEMBER,
                  Roles.ORGANIZATION_OWNER,
                  Roles.MANUFACTURER,
                  Roles.DEVELOPER,
                ]}
              />
            }
          >
            <Route path="/cabinet/*" element={<CabinetPage />} />
          </Route>
          <Route
            element={
              <PrivateRolesPage
                needRoles={[
                  Roles.DEVELOPER,
                  Roles.MANUFACTURER,
                  Roles.ORGANIZATION_MEMBER,
                  Roles.ORGANIZATION_OWNER,
                ]}
              />
            }
          >
            <Route path="/machineControl/*" element={<MachineControlPage />} />
          </Route>
          <Route
            element={
              <PrivateRolesPage
                needRoles={[Roles.ORGANIZATION_MEMBER, Roles.ORGANIZATION_OWNER]}
                needOrganization
              />
            }
          >
            <Route path="/promoCode/*" element={<PromoCodePage />} />
          </Route>
          <Route
            element={
              <PrivateRolesPage
                needRoles={[Roles.ORGANIZATION_MEMBER, Roles.ORGANIZATION_OWNER]}
                needOrganization
              />
            }
          >
            <Route path="/store/*" element={<OutletPage />} />
          </Route>
          <Route path="/errorPage" element={<ErrorPage />} />
          <Route path="/*" element={<Text>Домашняя страница</Text>} />
        </Routes>
      </div>
    </div>
  );

  return (
    <Theme className={styles.theme} preset={getPreset(theme)}>
      {/*<DisplayTypeContext.Provider*/}
      {/*  value={screenWidth > 1800 ? DisplayType.DESKTOP : DisplayType.LAPTOP}*/}
      {/*>*/}
      <Routes>
        <Route element={<PrivateRouteGuard needAuth={false} />}>
          <Route path="auth/*" element={<AuthPage />} />
        </Route>
        <Route element={<PrivateRouteGuard needAuth={true} />}>
          <Route path="/*" element={renderNeedAuthRoute()} />
        </Route>
      </Routes>
      {/*</DisplayTypeContext.Provider>*/}
    </Theme>
  );
};

export default App;
