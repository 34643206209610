import { AppDispatch } from '../../app/store';
import {
  createPromoCodeGroupThunk,
  createPromoCodeThunk,
  editPromoCodeThunk,
  generatePromoCodeThunk,
  getFilterPromoCodeThunk,
  getPromoCodeGroupListThunk,
  getPromoCodeListByExportThunk,
  getPromoCodeListThunk,
  getPromoCodeThunk,
  switchSelectPromoCodeThunk,
} from './thunk';
import {
  CreatePromoCodeGroupReq,
  CreatePromoCodeReq,
  EditPromoCodeReq,
  PromoCodeListFiltersReq,
} from '../../types/serverInterface/promoCodeDTO';
import { Pagination } from '../../types/types';

/**
 * Создание промокода
 *
 * @param data данные формы создания промокода
 */
export const createPromoCodeGroupAction =
  (data: CreatePromoCodeGroupReq) => (dispatch: AppDispatch) =>
    dispatch(createPromoCodeGroupThunk(data)).unwrap();

/**
 * Получение списка групп промокодов организации
 *
 * @param organizationId id организации
 */
export const getPromoCodeGroupListAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(getPromoCodeGroupListThunk(organizationId));

/**
 * Генерация промокода
 *
 * @param organizationId id организации
 */
export const generatePromoCodeAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(generatePromoCodeThunk(organizationId)).unwrap();

/**
 * Создания промокода
 *
 * @param data данные формы создания промокода
 */
export const createPromoCodeAction = (data: CreatePromoCodeReq) => (dispatch: AppDispatch) =>
  dispatch(createPromoCodeThunk(data)).unwrap();

/**
 * Изменение промокода
 *
 * @param data данные формы изменения промокода
 * @param promoCodeId  id промокода
 */
export const editPromoCodeAction =
  (data: EditPromoCodeReq, promoCodeId: number) => (dispatch: AppDispatch) =>
    dispatch(editPromoCodeThunk({ data, promoCodeId }));

/**
 * Получение информации о промокоде
 *
 * @param promoCodeId id промокода
 */
export const getPromoCodeAction = (promoCodeId: number) => (dispatch: AppDispatch) =>
  dispatch(getPromoCodeThunk(promoCodeId));

/**
 * Получение списка промокодов
 *
 * @param organizationId id организации
 * @param filters фильтры
 * @param pagination страницы
 */
export const getPromoCodeListAction =
  (organizationId: number, filters: PromoCodeListFiltersReq, pagination: Pagination) =>
  (dispatch: AppDispatch) =>
    dispatch(getPromoCodeListThunk({ filters, pagination, organizationId }));

/**
 * Получение списка промокодов для экспорта
 *
 * @param organizationId id организации
 * @param filters фильтры
 * @param pagination страницы
 */
export const getPromoCodeListByExportAction =
  (organizationId: number, filters: PromoCodeListFiltersReq, pagination: Pagination) =>
  (dispatch: AppDispatch) =>
    dispatch(getPromoCodeListByExportThunk({ filters, pagination, organizationId })).unwrap();

/**
 * Переключение состояние избранности промокода
 *
 * @param promoCodeId id промокода
 * @param isSelected флаг выбранного  промокода
 */
export const switchSelectPromoCodeAction =
  (promoCodeId: number, isSelected: boolean) => (dispatch: AppDispatch) =>
    dispatch(switchSelectPromoCodeThunk({ promoCodeId, isSelected })).unwrap();

/**
 * Получение пограничных значений для фильтров списка промокодов
 *
 * @param organizationId id организации
 * @param filters Текущие фильтры
 */
export const getFilterPromoCodeAction =
  (organizationId: number, filters: PromoCodeListFiltersReq) => (dispatch: AppDispatch) =>
    dispatch(getFilterPromoCodeThunk({ organizationId, filters }));
