import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { useTranslation } from 'react-i18next';
import { selectMachineModelList } from '../../../state/machineControl/selectors';
import {
  deleteMachineModelAction,
  getMachineModelListAction,
} from '../../../state/machineControl/actions';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { IconAdd } from '@consta/uikit/IconAdd';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { MachineModelDTO } from '../../../types/serverInterface/machineModelDTO';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconInfo } from '@consta/uikit/IconInfo';
import { ListLoader } from '@consta/uikit/ListCanary';
import TableCard from '../../../components/TableCard';
import CreateModel from './CreateModel';
import ModelInfo from './ModelInfo';
import DeleteModel from './DeleteModel';
import CellsScheme from './CellsScheme';

/**
 * Список моделей автомата
 */
const ModelList: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { state: modelList, isLoading } = useAppSelector(selectMachineModelList());

  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [deletableId, setDeletableId] = useState<number | null>(null);
  const [infoId, setIsInfoId] = useState<number | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const filterModelList = useMemo(() => {
    return (
      modelList?.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase())) || []
    );
  }, [searchQuery, modelList]);

  useEffect(() => {
    dispatch(getMachineModelListAction());
  }, [dispatch]);

  // Обработчики
  const handleAddButtonClick = () => {
    setIsCreateOpen(true);
  };

  const handleAddClose = () => {
    setIsCreateOpen(false);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleDeleteClick = (id: number) => () => {
    setDeletableId(id);
  };

  const handleCancelDeleteClick = () => {
    setDeletableId(null);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);
    deletableId &&
      dispatch(deleteMachineModelAction(deletableId)).finally(() => {
        setIsDeleteLoading(false);
        setDeletableId(null);
      });
  };

  const handleInfoClick = (id: number) => () => {
    setIsInfoId(id);
  };

  const handleInfoClose = () => {
    setIsInfoId(null);
  };

  // render методы
  const renderActions = () => (
    <HorizontalContainer space="xl">
      <Button
        label={t('machineControl.model.list.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={handleAddButtonClick}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </HorizontalContainer>
  );

  const renderRowAction = ({ id }: MachineModelDTO) => (
    <HorizontalContainer>
      <Button view="ghost" iconLeft={IconInfo as any} onClick={handleInfoClick(id)} />
      <Button view="ghost" iconLeft={IconTrash as any} onClick={handleDeleteClick(id)} />
    </HorizontalContainer>
  );

  const renderCellsScheme = (model: MachineModelDTO) => <CellsScheme modelCells={model} />;

  const renderCreateForm = () => <CreateModel isOpen={isCreateOpen} onClose={handleAddClose} />;

  const renderInfo = () => infoId && <ModelInfo modelId={infoId} onClose={handleInfoClose} />;

  const renderDeleteModal = () =>
    deletableId && (
      <DeleteModel
        isOpen={!!deletableId}
        isDeleteLoading={isDeleteLoading}
        modelId={deletableId}
        onDelete={handleDelete}
        onClose={handleCancelDeleteClick}
      />
    );

  if (isLoading)
    return (
      <ListLoader
        placeholder=""
        onPointerEnterCapture={() => {
          null;
        }}
        onPointerLeaveCapture={() => {
          null;
        }}
      />
    );

  return (
    <div>
      {renderCreateForm()}
      {renderInfo()}
      {renderDeleteModal()}
      {renderActions()}
      <TableCard
        rows={filterModelList}
        columns={{
          name: {
            key: 'name',
            title: t('machineControl.model.list.name.column.title'),
            alignment: 'left',
            getItemLabel: (data) => data.name,
          },
          cells: {
            key: 'cells',
            title: t('machineControl.model.list.cells.column.title'),
            alignment: 'left',
            renderCell: renderCellsScheme,
          },
          id: {
            key: 'id',
            title: t('machineControl.model.list.actions.column.title'),
            alignment: 'right',
            renderCell: renderRowAction,
          },
        }}
      />
    </div>
  );
};

export default ModelList;
