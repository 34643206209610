import { FC, useEffect, useState } from 'react';
import DefaultModal from '../../../../components/DefaultModal';
import BaseModelInfo from './BaseModelInfo';
import CellsModelInfo from './CellsModelInfo';
import Chips from '../../../../components/Chips';
import { enumToArray } from '../../../../types/enums';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectMachineModelDetails } from '../../../../state/machineControl/selectors';
import { getMachineModelDetailsAction } from '../../../../state/machineControl/actions';
import {
  CreateBaseModelDTO,
  CellsModelDTO,
} from '../../../../types/serverInterface/machineModelDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useTranslation } from 'react-i18next';

/**
 * Шаги в информационной странице
 */
export enum InfoModelSteps {
  /**
   * Базовая информация
   */
  BASE = 'BASE',
  /**
   * Ячейки
   */
  CELLS = 'CELLS',
}

/**
 * Список шагов в информационной странице
 */
const infoModelStepsList = enumToArray(InfoModelSteps);

/**
 * Свойства комопнента ModelInfo
 */
type ModelInfoProps = {
  /**
   * id модели автомата
   */
  modelId: number;
  /**
   * Обработчик закрытия страницы информации
   */
  onClose: () => void;
};

/**
 * Информация о модели автомата
 */
const ModelInfo: FC<ModelInfoProps> = ({ modelId, onClose }) => {
  const { t } = useTranslation();

  const { state: model } = useAppSelector(selectMachineModelDetails());
  const dispatch = useAppDispatch();

  const [infoStep, setIsInfoStep] = useState<InfoModelSteps>(InfoModelSteps.BASE);

  useEffect(() => {
    dispatch(getMachineModelDetailsAction(modelId));
  }, [dispatch, modelId]);

  // Обработчики
  const handleChipsClick = (item: string) => {
    setIsInfoStep(item as InfoModelSteps);
  };

  const handleClose = () => {
    onClose();
  };

  // render методы
  const renderAction = () => (
    <>
      <Button
        label={t('machineControl.model.info.cancel.button')}
        view="clear"
        onClick={handleClose}
      />
    </>
  );

  const renderBaseModelInfo = (model: CreateBaseModelDTO) => <BaseModelInfo model={model} />;

  const renderCellModelInfo = (model: CellsModelDTO) => <CellsModelInfo modelCells={model} />;

  const renderContent = () => {
    if (!model) return null;

    switch (infoStep) {
      case InfoModelSteps.BASE:
        return renderBaseModelInfo(model);
      case InfoModelSteps.CELLS:
        return renderCellModelInfo(model);
    }
  };

  const renderChips = () => (
    <HorizontalContainer space="xs">
      {infoModelStepsList.map((item) => (
        <Chips
          key={item}
          label={t(`machineControl.model.info.chips.${item}`)}
          name={item}
          isSelect={infoStep === item}
          onClick={handleChipsClick}
        />
      ))}
    </HorizontalContainer>
  );

  return (
    <DefaultModal
      isOpen
      modalTitle={t('machineControl.model.info.modal.title')}
      renderActions={renderAction}
      onClose={handleClose}
    >
      {renderChips()}
      {renderContent()}
    </DefaultModal>
  );
};

export default ModelInfo;
